import { useEffect, useState, Fragment } from "react";
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Select,
  Divider,
  Popconfirm,
  message,
  Upload,
  List,
  Image,
  Checkbox,
} from "antd";
import {
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { BREADCRUMB_ADD_NEW_SWEEPSTAKE } from "../../../../../utils/benefits/constant";
import {
  Modal,
  Button,
  Radio,
  ModalHeader,
  NoData,
  Card,
} from "../../../../../components/customAntd";
import Dragger from "antd/es/upload/Dragger";
import { uploadFiles, getLocalesFromDb } from "../../../../../utils/common/apiUtils";
import { useAppContext } from "../../../../../components/context/app.context";

import Styles from "../styles/discountCodes.module.scss";

const { Item } = Form;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Meta } = Card;

const NewCode = ({
  form,
  isOpen,
  onCancel,
  onSubmitClick,
  onSubmit,
  loading,
  isDataLoading,
  isEdit,
  setMerchantData,
  editData,
  setHeroImage,
  heroImage,
  setIsDeletingBenefit,
  categories,
  isDeletingBenefit,
}) => {
  const [files, setFiles] = useState([]);
  const [isHeroLoading, setIsHeroLoading] = useState(false);
  const [isHeroPreviewVisible, setHeroPreviewVisible] = useState(false);
  const [defaultLocale] = useState("en_US");
  const [availableLocales, setAvailableLocales] = useState([]);
  const [submitData, setSubmitData] = useState(null);
  const unlimitedUse = Form.useWatch("unlimited_use", form);
  const watchLocales = Form.useWatch("locales", form);
  // const watchStandaloneSweepstake = Form.useWatch("standaloneSweepstake", form);
  const watchDefaultLanguage = Form.useWatch("defaultLocaleId", form);
  const watchCategories = Form.useWatch("primary_category", form);
  const { currentLocale } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (submitData) {
      onSubmit(submitData);
    } else {
      setSubmitData(null);
    }
  }, [submitData]);

  useEffect(() => {
    if (unlimitedUse === "yes") {
      form.setFieldValue("use_limited_pass", null);
      form.setFieldValue("use_limited_total", null);
    }
  }, [unlimitedUse, form]);

  useEffect(() => {
    const getLocales = async () => {
      const data = await getLocalesFromDb();
      if (data) {
        const localesData = data.map((locale) => ({
          value: locale.localeId,
          label: `${locale.language.language} (${locale.country.country})`,
          isSelected: locale.localeId === defaultLocale,
        }));
        setAvailableLocales(localesData);
      }
    };
    getLocales();
  }, [defaultLocale]);

  useEffect(() => {
    // Set Available locales on editing the discount code
    if (isEdit && editData) {
      setAvailableLocales((prev) => {
        return prev.map((locale) => {
          return {
            ...locale,
            isSelected:
              !!editData?.locales?.find(
                (localeEl) => localeEl.localeId === locale.value
              ) || editData.defaultLocaleId === locale.value,
          };
        });
      });
    }
  }, [isEdit, editData]);

  const handleDeleteClickHero = (fileName) => {
    setHeroImage([]);
    form.setFieldValue("hero_image", undefined);
  };

  // Change hero image
  const handleHeroImageChange = (data) => {
    setHeroImage([...data]);
    setFiles([...files, ...data]);
  };

  const handleBeforeUpload = async (file) => {
    const extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    const currentFileIndexOf = files.findIndex((i) => i.name === file.name);
    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.ONLY")} JPG, JPEG and PNG ${t(
          "FILE_UPLOAD.EXTENSIONS_ALLOWED"
        )}`,
      });
      form.setFieldValue("hero_image", undefined);
      return Upload.LIST_IGNORE;
    } else if (currentFileIndexOf !== -1) {
      message.open({
        type: "error",
        content: t("FILE_UPLOAD.EXISTS"),
      });
      return Upload.LIST_IGNORE;
    } else if (file.size > 10000000) {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.SIZE_ERROR")} 10${t("MB")}`,
      });
      return Upload.LIST_IGNORE;
    } else {
      setIsHeroLoading(true);
      const uploadFile = await uploadFiles({
        fileFor: "benefit",
        isUploadIpfs: false,
        files: file,
      });

      setIsHeroLoading(false);
      if (uploadFile?.status === 200) {
        const uploadArray = uploadFile.data.map((item) => {
          return {
            name: item.s3Url.split("/").reverse()[0],
            resourceUrl: item.s3Url,
            type: "heroImage",
          };
        });
        handleHeroImageChange(uploadArray);
      }
      setIsHeroLoading(false);
    }
  };

  // Updated locales state if someone changed language in the form
  const updateLocales = (value, prevValue) => {
    setAvailableLocales((prev) => {
      return prev.map((item) =>
        item.value === value
          ? {
              ...item,
              isSelected: true,
            }
          : {
              ...item,
              isSelected: item.value === prevValue ? false : item.isSelected,
            }
      );
    });
  };

  const onSelect = (value, option) => {
    setMerchantData((prev) =>
      prev.map((item) => {
        return {
          ...item,
          isSelected: item.id === option.id,
        };
      })
    );
  };

  //Setting available locales to default state after close, cancel or edit action
  const setLocales = () => {
    setAvailableLocales((prev) => {
      return prev.map((locale) => {
        return {
          ...locale,
          isSelected: locale.value === defaultLocale,
        };
      });
    });
  };

  return (
    <Modal
      title={
        <ModalHeader
          title={isEdit ? t("SWEEPSTAKES.EDIT") : t("SWEEPSTAKES.NEW_CODE")}
          breadcrumbs={BREADCRUMB_ADD_NEW_SWEEPSTAKE(t)}
        />
      }
      open={isOpen}
      centered
      width={1200}
      onCancel={() => {
        onCancel();
        setLocales();
      }}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button
          buttonType="cancel"
          onClick={() => {
            onCancel();
            setLocales();
          }}
          key="cancel-button"
        >
          {t("CANCEL")}
        </Button>,
        <Button
          onClick={() => {
            console.log(form.getFieldValue("redeemable_period"), "date range");
            onSubmitClick();
            setLocales();
          }}
          loading={loading || isDataLoading}
          key="create-button"
        >
          {isDataLoading
            ? `${t("LOADING")}...`
            : isEdit
            ? t("SWEEPSTAKES.UPDATE")
            : t("SWEEPSTAKES.CREATE")}
        </Button>,
      ]}
    >
      <Row gutter={52}>
        <Col xs={24} sm={12}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            disabled={loading || isDataLoading}
            requiredMark={false}
          >
            <Item
              label={`${t("DEFAULT_LANGUAGE")}:`}
              name="defaultLocaleId"
              initialValue={defaultLocale}
              rules={[
                {
                  required: true,
                  message: t("DEFAULT_LANGUAGE_ERROR"),
                  whitespace: true,
                },
              ]}
            >
              <Select
                options={availableLocales.map((localEl) => {
                  return {
                    ...localEl,
                    disabled: localEl.isSelected,
                  };
                })}
                onChange={(value) => updateLocales(value, watchDefaultLanguage)}
              />
            </Item>
            <Row>
              <Item
                label={`${t("BENEFIT_CATEGORIES.LABEL")} :`}
                name="primary_category"
                rules={[
                  {
                    required: true,
                    message: t("BENEFIT_CATEGORIES.SELECT_CATEGORY"),
                  },
                ]}
              >
                <Select
                  style={{ width: 200 }}
                  className="mr-2"
                  placeholder={t("MERCHANT.SELECT_PRIMARY")}
                  onChange={() => {
                    form.setFieldValue("subcategory", null);
                  }}
                  options={[
                    ...categories?.map((category) => ({
                      value: category.id,
                      label: t(`${category["name"].toUpperCase()}`),
                    })),
                  ]}
                />
              </Item>
              <Item label=" " name="subcategory">
                <Select
                  style={{ width: 200 }}
                  placeholder={t("MERCHANT.SELECT_SUB")}
                  allowClear
                  options={
                    watchCategories > 0
                      ? [
                          ...categories
                            ?.find(
                              (category) => category.id === watchCategories
                            )
                            ?.subCategories.map((category) => ({
                              value: category.id,
                              label: category.name,
                            })),
                        ]
                      : [{ value: 0, label: t("MERCHANT.SUB_CAT_OPTIONAL") }]
                  }
                  disabled={!watchCategories || watchCategories === 0}
                />
              </Item>
            </Row>
            <h3>{t("BENEFIT_TYPES.SWEEPSTAKES.TITLE")}</h3>

            {isEdit && (
              <Item name="id" hidden>
                <Input />
              </Item>
            )}

            <Item
              label={`${t("SWEEPSTAKES.NAME")}`}
              name="code_name"
              rules={[
                {
                  required: true,
                  message: t("SWEEPSTAKES.CODE_NAME_ERROR"),
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t("SWEEPSTAKES.NAME_PLACEHOLDER")} />
            </Item>
            <Item
              label={`${t("SWEEPSTAKES.TITLE_PREFIX")}:`}
              name="code_short_description"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && value.length > 10) {
                      return Promise.reject(
                        new Error(t("SWEEPSTAKES.CODE_PREFIX_VALIDATION_ERROR"))
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <TextArea
                placeholder={t("PREFIX_TITLE_VALIDATION_ERROR")}
                style={{ resize: "none" }}
              />
            </Item>

            <Item
              label={`${t("SWEEPSTAKES.DESC")}:`}
              name="code_description"
              rules={[
                {
                  required: true,
                  message: t("SWEEPSTAKES.DESC_ERROR"),
                  whitespace: true,
                },
              ]}
            >
              <TextArea
                placeholder={t("SWEEPSTAKES.DESC_PLACEHOLDER")}
                style={{ resize: "none" }}
              />
            </Item>
            <Item label={`${t("DISCLAIMER")}:`} name="disclaimer">
              <MDEditor
                overflow={false}
                placeholder={t("SWEEPSTAKES.DISCLAIMER_PLACEHOLDER")}
                // value={value}
                // onChange={setValue}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
              {/* <TextArea
                placeholder={t("SWEEPSTAKES.DISCLAIMER_PLACEHOLDER")}
                style={{ resize: "none" }}
              /> */}
            </Item>

            <Item
              label={`${t("HERO_IMAGE")}:`}
              name="hero_image"
              extra={
                <>
                  <div>{t("RECOMMENDED_ASPECT_RATIO")}</div>
                  <div>
                    {t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG, GIF, SVG,{" "}
                    {t("MAX_SIZE")}: 1{t("MB")}
                  </div>
                </>
              }
              rules={[
                {
                  required: isEdit && heroImage.length !== 0 ? false : true,
                  message: t("UPLOAD_HERO_IMAGE"),
                },
              ]}
            >
              <Dragger
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
                disabled={isHeroLoading}
                accept="image/jpg, image/jpeg, image/png"
                customRequest={() => null}
                fileList={files}
                maxCount={1}
              >
                {isHeroLoading ? (
                  <>
                    <LoadingOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{`${t("UPLOADING")}...`}</p>
                  </>
                ) : (
                  <>
                    <UploadOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{t("UPLOAD")}</p>
                  </>
                )}
              </Dragger>
            </Item>
            {heroImage.length !== 0 && (
              <Item label={`${t("HERO_IMAGE")}:`}>
                <List
                  dataSource={heroImage}
                  className="upload-list"
                  style={{ marginBottom: 20 }}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <DeleteOutlined
                          onClick={() => handleDeleteClickHero(item.name)}
                        />,
                        <EyeOutlined
                          onClick={() =>
                            setHeroPreviewVisible(!isHeroPreviewVisible)
                          }
                        />,
                      ]}
                    >
                      <div>
                        <span className="item-index">{index + 1}.</span>{" "}
                        <span className="item-name">
                          {item.name ?? t("HERO_IMAGE_FILE")}
                        </span>
                      </div>
                    </List.Item>
                  )}
                />
              </Item>
            )}
            {heroImage[0]?.resourceUrl && (
              <Image
                className={Styles.imagePreview}
                src={heroImage[0].resourceUrl}
                preview={{
                  visible: isHeroPreviewVisible,
                  onVisibleChange: (visible, prevVisible) =>
                    setHeroPreviewVisible(visible),
                }}
              />
            )}
            <Item
              label={`${t("REDEEMABLE_PERIOD")}:`}
              name="redeemable_period"
              rules={[
                  {
                    required: true,
                    message: t("REDEEMABLE_PERIOD_ERROR"),
                  },
                  {
                    validator: async (_, val) => {
                      if (val) {
                        const [startDate, endDate] = val;
                        if (dayjs(startDate).isAfter(endDate)) {
                          return Promise.reject(t("REDEEMABLE_PERIOD_ERROR"));
                        }
                      }
                    },
                  },
                  
                ]}
            >
              <RangePicker
                initialValue={isEdit && [editData?.startDate, editData?.endDate]}
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                // disabled={[
                //   isEdit &&
                //     (editData?._count?.sweepStakesRegistrations > 0 ||
                //       editData?.sweepStake?.resultAnnounced),
                //   isEdit && editData?.sweepStake?.resultAnnounced,
                // ]}
                disabledDate={(current) => {
                  const resultDate = form.getFieldValue([
                    "sweepStake",
                    "resultAnnouncementDate",
                  ]);
                  return (
                    isEdit ? 
                      ((current && current < dayjs(editData?.activeDate).endOf('day')) || (current && current > dayjs(resultDate).subtract(1, "day").endOf("day")))
                      : current && current < dayjs().subtract(1, "day").endOf("day") 
                  );
                }}
                placeholder={[t("START_DATE"), t("END_DATE")]}
                showTime={{ format: "HH:mm" }}
              />
            </Item>
            <Item
              label={`${t("SWEEPSTAKE_RESULT_DATE")}:`}
              name={["sweepStake", "resultAnnouncementDate"]}
              dependencies={["redeemable_period"]}
              rules={[
                {
                  required: true,
                  message: t("SWEEPSTAKES.RESULT_DATE_REQUIRED"),
                },
                {
                  validator: async (_, val) => {
                    if (val && form.getFieldValue("redeemable_period")) {
                      const [, endDate] =
                        form.getFieldValue("redeemable_period");
                      if (val.isBefore(endDate)) {
                        return Promise.reject(
                          t("SWEEPSTAKES.RESULT_DATE_ERROR")
                        );
                      }
                    }
                  },
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                disabledDate={(current) => {
                  return (
                    (current &&
                      (!isEdit ||
                        (isEdit &&
                          editData?.sweepStake?.resultAnnounced)) &&
                      current < dayjs().subtract(1, "day").endOf("day")) ||
                    (current &&
                      form.getFieldValue("redeemable_period")?.[1] &&
                      current < form.getFieldValue("redeemable_period")[1])
                  );
                }}
                placeholder={[t("SWEEPSTAKE_RESULT_DATE")]}
                showTime={{ format: "HH:mm" }}
              />
            </Item>
            <Item
              name={["sweepStake", "showUntilDate"]}
              label={`${t("SWEEPSTAKES.SHOW_UNTIL")}`}
              dependencies={["sweepStake", "resultAnnouncementDate"]}
              rules={[
                {
                  required: true,
                  message: t("SWEEPSTAKES.SHOW_UNTIL_DATE_REQUIRED"),
                },
                {
                  validator: async (_, val) => {
                    const resultDate = form.getFieldValue([
                      "sweepStake",
                      "resultAnnouncementDate",
                    ]);
                    if (val && resultDate) {
                      if (dayjs(val).isBefore(resultDate)) {
                        return Promise.reject(
                          t("SWEEPSTAKES.SHOW_UNTIL_ERROR")
                        );
                      }
                    }
                  },
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                disabledDate={(current) => {
                  return (
                    (current &&
                      (!isEdit ||
                        (isEdit &&
                          editData?.sweepStake?.resultAnnounced)) &&
                      current < dayjs().subtract(1, "day").endOf("day")) ||
                    (form.getFieldValue([
                      "sweepStake",
                      "resultAnnouncementDate",
                    ]) &&
                      current <
                        form.getFieldValue([
                          "sweepStake",
                          "resultAnnouncementDate",
                        ]))
                  );
                }}
                showTime={{ format: "HH:mm" }}
                placeholder={[t("SWEEPSTAKES.SHOW_UNTIL_PLACEHOLDER")]}
              />
            </Item>
            <Item
              label={`${t("SWEEPSTAKE_PRIZE_COUNT")}:`}
              name={["sweepStake", "rewardCount"]}
              rules={[
                {
                  required: true,
                  message: t("SWEEPSTAKE_PRIZE_COUNT_ERROR"),
                },
              ]}
            >
              <Input
                type="number"
                onWheel={(e) => e.target.blur()}
                min={1}
                placeholder="1"
              />
            </Item>
            <Item
              name={["sweepStake", "isFastPass"]}
              valuePropName="checked"
            >
              <Checkbox>{t("SWEEPSTAKES.ENABLE_FAST_PASS")}</Checkbox>
            </Item>
            <Item
              label={`${t("STATUS")}:`}
              name="status"
              rules={[
                {
                  required: true,
                  message: t("SELECT_STATUS"),
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value="draft">
                      {t("DRAFT")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value="active">
                      {t("ACTIVE")}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Item>
            <Item label={`${t("ACTIVE_DATE")}:`} name="active_date">
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                disabled={
                  isEdit && editData?._count?.sweepStakesRegistrations > 0
                }
                disabledDate={(current) => {
                  return (
                    current && current < dayjs().subtract(1, "day").endOf("day")
                  );
                }}
                placeholder={[t("ACTIVE_DATE")]}
                showTime={{ format: "HH:mm" }}
              />
            </Item>
            <Item
              label={`${t("MEMBERSHIP_REQUIRED")}:`}
              name="membershipCriteria"
              rules={[
                {
                  required: true,
                  message: t("MEMBERSHIP_STATUS"),
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row gutter={[20, 20]}>
                  <Col xs={24} md={8}>
                    <Radio style={{ width: "100%" }} value="NotRequired">
                      {/* {t("NO")} */}
                      {t("NOT_REQUIRED")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={8}>
                    <Radio style={{ width: "100%" }} value="Visible">
                      {/* {t("YES")} */}
                      {t("VISIBLE")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={8}>
                    <Radio style={{ width: "100%" }} value="NotVisible">
                      {/* {t("YES")} */}
                      {t("NOT_VISIBLE")}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Item>
            <Item
              label={`${t("SHOWIMAGES_ONLY")}:`}
              name="show_images_only"
              rules={[
                {
                  required: true,
                  message: t("SHOWIMAGES_ONLY_ERROR"),
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value={false}>
                      {t("NO")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value={true}>
                      {t("YES")}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Item>
            {/* <Item
              label={`${t("STANDALONE_SWEEPSTAKE")}:`}
              name="standaloneSweepstake"
              initialValue={true}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value={false}>
                      {t("NO")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value={true}>
                      {t("YES")}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Item> */}
            {/* {watchStandaloneSweepstake && (
              
            )} */}
            <Form.List name="locales">
              {(fields, { add, remove }) => (
                <div className="languageOptions">
                  <h3 className="heading">{t("LANGUAGE_OPTIONS.HEADING")}</h3>
                  <p className="info">{t("LANGUAGE_OPTIONS.DESCRIPTION")}</p>
                  {fields.map(({ key, name }, index) => (
                    <Fragment key={key}>
                      <Item
                        label={`${t("LANGUAGE")} :`}
                        name={[name, "localeId"]}
                        rules={[
                          {
                            required: true,
                            message: t("LANGUAGE_OPTIONS.SELECT_LANGUAGE"),
                          },
                        ]}
                      >
                        <Select
                          options={availableLocales.map((localEl) => ({
                            ...localEl,
                            disabled: localEl.isSelected,
                          }))}
                          onChange={(value) =>
                            updateLocales(value, watchLocales[index]?.localeId)
                          }
                          placeholder={t("SELECT")}
                        />
                      </Item>
                      <Item
                        label={`${t("LANGUAGE_OPTIONS.NAME")} :`}
                        name={[name, "title"]}
                        rules={[
                          {
                            required: true,
                            message: t("LANGUAGE_OPTIONS.ENTER_NAME"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t(
                            "SWEEPSTAKES.LOCALISED_NAME_PLACEHOLDER"
                          )}
                        />
                      </Item>
                      <Item
                        label={`${t("LANGUAGE_OPTIONS.DESC")} :`}
                        name={[name, "description"]}
                        rules={[
                          {
                            required: true,
                            message: t("LANGUAGE_OPTIONS.ENTER_DESC"),
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input.TextArea
                          placeholder={t(
                            "SWEEPSTAKES.LOCALISED_DESCRIPTION_PLACEHOLDER"
                          )}
                        />
                      </Item>
                      <Item
                        label={`${t("SWEEPSTAKES.LOCALISED_DISCLAIMER")} :`}
                        name={[name, "disclaimer"]}
                      >
                        <MDEditor
                          overflow={false}
                          placeholder={t(
                            "SWEEPSTAKES.LOCALISED_DISCLAIMER_PLACEHOLDER"
                          )}
                          // value={value}
                          // onChange={setValue}
                          previewOptions={{
                            rehypePlugins: [[rehypeSanitize]],
                          }}
                        />
                        {/* <TextArea
                          placeholder={t(
                            "SWEEPSTAKES.LOCALISED_DISCLAIMER_PLACEHOLDER"
                          )}
                          style={{ resize: "none" }}
                        /> */}
                      </Item>
                      {fields.length > 0 && (
                        <Divider orientation="center">
                          <Popconfirm
                            title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                            onConfirm={() => {
                              setAvailableLocales((prev) => {
                                return prev.map((item) =>
                                  item.value === watchLocales[index].localeId
                                    ? {
                                        ...item,
                                        isSelected: false,
                                      }
                                    : item
                                );
                              });
                              remove(name);
                            }}
                            onCancel={() => {}}
                            okText={t("YES")}
                            cancelText={t("NO")}
                          >
                            <DeleteOutlined
                              className="deleteIcon"
                              style={{ fontSize: 24 }}
                            />
                          </Popconfirm>
                        </Divider>
                      )}
                    </Fragment>
                  ))}

                  <Button
                    type="primary"
                    className="add-locale-button"
                    onClick={fields.length >= 3 ? () => {} : () => add()}
                    icon={<PlusOutlined />}
                    disabled={fields.length >= 3}
                  >
                    {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
                  </Button>
                </div>
              )}
            </Form.List>
          </Form>
        </Col>
        <Col xs={24} sm={12}>
          {editData?.sweepStake && !editData.sweepStake?.isFastPass && (
            <>
              <h3>{t("ATTACHED_BENEFITS")}</h3>
              {editData?.sweepStake?.rewards?.length > 0 ? (
                <Row gutter={[20, 20]}>
                  {editData?.sweepStake?.rewards?.map((item) => {
                    const currentLocaleData = item?.locales?.find(
                      (locale) => locale.localeId === currentLocale
                    );
                    return (
                      <Col md={8} sm={12}>
                        <Card
                          cover={
                            <img
                              src={
                                currentLocaleData?.previewResourceUrl ??
                                item.previewResourceUrl
                              }
                              height={130}
                              width="100%"
                              alt={item.name}
                              style={{ objectFit: "contain" }}
                            />
                          }
                          actions={[
                            <Popconfirm
                              title={t(
                                "SWEEPSTAKES.REMOVE_BENEFIT_CONFIRMATION"
                              )}
                              okText={t("YES")}
                              cancelText={t("NO")}
                              onConfirm={() => {
                                setIsDeletingBenefit(true);
                                setSubmitData([item.id]);
                              }}
                            >
                              <Button type="link">{t("REMOVE")}</Button>
                            </Popconfirm>,
                          ]}
                        >
                          <Meta
                            title={currentLocaleData?.title ?? item.title}
                          />
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                <NoData />
              )}
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default NewCode;

import { useEffect, useState } from "react";
import { Flex, message, Popconfirm, Spin, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useTranslation } from "react-i18next";

import { BREADCRUMB_ANNOUNCEMENT } from "../../../../utils/announcements/constant";
import {
  Button,
  Micons,
  NoData,
  PageHeader,
  Table,
  Tag,
} from "../../../../components/customAntd";
import NewPressReleaseModal from "./newPressReleaseModal";
import { deletePressReleaseFromDb, getAllPressReleaseList } from "./apiUtils";

dayjs.extend(isBetween);

const PressRelease = () => {
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [list, setList] = useState([]);
  const [currentEditActiveData, setCurrentEditActiveData] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const { t } = useTranslation();

  const columns = [
    {
      title: t("ANNOUNCEMENT.HEADING"),
      dataIndex: "heading",
      key: "heading",
    },

    {
      title: t("ANNOUNCEMENT.START_DATE"),
      dataIndex: "startDate",
      key: "startdate",
      width: 170,
      render: (startDate) => (
        <>
          <Tag isDisabled>{dayjs(startDate).format("MM/DD/YYYY hh:mm A")}</Tag>
        </>
      ),
    },
    {
      title: t("ANNOUNCEMENT.END_DATE"),
      dataIndex: "endDate",
      key: "enddate",
      width: 170,
      render: (endDate) => (
        <Tag isDisabled>{dayjs(endDate).format("MM/DD/YYYY hh:mm A")}</Tag>
      ),
    },
    {
      title: t("ANNOUNCEMENT.ACTIVE"),
      dataIndex: "active",
      key: "active",
      width: 70,
      render: (_, record) => {
        const isActive = dayjs().isBetween(record.startDate, record.endDate);
        return isActive ? t("YES") : t("NO");
      },
    },
    {
      title: t("ANNOUNCEMENT.ACTION"),
      dataIndex: "action",
      key: "action",
      width: 70,
      render: (_, record) => (
        <Flex gap={10}>
          <Popconfirm
            title={`${t("Are you sure you want to delete")} ${record.heading}?`}
            onConfirm={() => onDeletePress(record)}
            onCancel={() => {}}
            okText={t("YES")}
            cancelText={t("NO")}
          >
            <Button type="link">
              <Tooltip title={t("DELETE")}>
                <Micons icon="delete" className="table-action-icon" />
              </Tooltip>
            </Button>
          </Popconfirm>
          <Button onClick={() => onEditPress(record)} type="link">
            <Tooltip title={t("EDIT")}>
              <Micons icon="edit" className="table-action-icon" />
            </Tooltip>
          </Button>
        </Flex>
      ),
    },
  ];

  const onEditPress = (record) => {
    setCurrentEditActiveData(record);
    setIsNotificationsModalOpen(true);
  };

  const getAllPressRelease = async () => {
    setLoadingNotifications(true);
    const response = await getAllPressReleaseList(pagination);
    if (response?.data?.data) {
      setList(response.data.data);
      setPagination((prev) => ({ ...prev, total: response.data.totalCount }));
    } else {
      message.error({
        content: "Couldn't fetch press release",
        key: "get-error",
        duration: 2,
      });
    }
    setLoadingNotifications(false);
  };

  const onCancelPressReleaseModal = ({ data, isEdit }) => {
    setIsNotificationsModalOpen(false);
    setCurrentEditActiveData(null);
    if (data) {
      if (isEdit) {
        setList((prev) =>
          prev.map((item) => (item.id === data.id ? data : item))
        );
      } else {
        setList((prev) => [data, ...prev]);
      }
    }
  };

  const onDeletePress = async (record) => {
    const deletedPR = await deletePressReleaseFromDb(record.id);
    if (deletedPR?.status === 200) {
      setList((prev) => prev.filter((item) => item.id !== deletedPR.data.id));
    } else {
      message.error({
        content: "Couldn't delete press release",
        key: "get-error",
        duration: 2,
      });
    }
  };

  useEffect(() => {
    getAllPressRelease();
  }, [pagination.current]);

  return (
    <>
      <PageHeader
        title={t("ANNOUNCEMENT.PRESS_RELEASE")}
        breadcrumbs={BREADCRUMB_ANNOUNCEMENT(
          t,
          t("ANNOUNCEMENT.PRESS_RELEASE")
        )}
        extra={
          <div style={{ display: "flex" }}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setIsNotificationsModalOpen(true);
              }}
            >
              {t("ANNOUNCEMENT.PRESS_RELEASE")}
            </Button>
          </div>
        }
      />

      {loadingNotifications ? (
        <Spin size="small" style={{ marginTop: 30, width: "100%" }} />
      ) : list.length > 0 ? (
        <Table
          dataSource={list}
          columns={columns}
          pagination={{
            ...pagination,
            onChange: (page) => {
              setPagination((prev) => ({ ...prev, current: page }));
            },
          }}
        />
      ) : (
        <NoData />
      )}

      <NewPressReleaseModal
        isOpen={isNotificationsModalOpen}
        onCancelPressReleaseModal={onCancelPressReleaseModal}
        currentEditActiveData={currentEditActiveData}
      />
    </>
  );
};

export default PressRelease;

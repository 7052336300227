import { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import {
  Space,
  Row,
  Col,
  Skeleton,
  Form,
  Popconfirm,
  message,
  Tooltip,
  Dropdown,
} from "antd";
import { useTranslation } from "react-i18next";

//import Custom Components
import {
  PageHeader,
  Button,
  Table,
  Tag,
  Card,
  NoData,
  Micons,
} from "../../../../components/customAntd";
import DiscountCodesFilters from "../gatedVideo/filters";
import {
  debounce,
  getCategoryIdFromName,
  getMerchantCategoryAndSubCategory,
} from "../../../../utils/helper";
import ReadMore from "../../../../components/readmore";
import { useAppContext } from "../../../../components/context/app.context";
import {
  deleteBenefitFromDb,
  getAllBenefitsFromDb,
  addBenefitToDb,
  updateBenefitToDb,
} from "../apiUtils";
import DefaultImage from "../../../../assets/images/default-img.jpg";
import NotificationsModal from "../sweepstakes/notifications";

//import constants
import { BREADCRUMB_SURVEY } from "../../../../utils/benefits/constant";

//Import styles
import Styles from "./styles/ebooks.module.scss";
import NewSurvey from "./newSurvey";

const { Meta } = Card;
const Survey = () => {
  const [form] = Form.useForm();
  const [displayType, setDisplayType] = useState("list");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [coverImage, setCoverImage] = useState("");
  const [localisedCoverImages, setLocalisedCoverImages] = useState([]);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [filters, setFilters] = useState({
    filterCondition: { benefitType: "survey" },
  });
  const { t } = useTranslation();
  const { currentLocale, merchantCategories } = useAppContext();

  // Get all ebooks initially
  useEffect(() => {
    const fetchEbooks = async () => {
      setIsLoading(true);
      const getAllBenefits = await getAllBenefitsFromDb(filters);
      if (getAllBenefits?.status === 200) {
        setData(getAllBenefits.data);
      }
      setIsLoading(false);
    };
    fetchEbooks();
  }, [filters]);

  // Handler for the search input
  const handleSearch = debounce((e) => {
    const searchTerm = e.target.value;
    if (searchTerm !== "") {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            search: searchTerm,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.search;
      setFilters(filtersCopy);
    }
  });

  // Handler for the filter select
  const handleFilterByChange = (value) => {
    if (value) {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            status: value,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.status;
      setFilters(filtersCopy);
    }
  };

  // Handler for the deleting ebook
  const handleDelete = async (recordId) => {
    const deletedEbook = await deleteBenefitFromDb(recordId);
    if (deletedEbook?.status === 200) {
      setData((prev) =>
        prev.filter((item) => item.id !== deletedEbook.data.id)
      );
    }
  };

  //Edit
  const handleEditClick = (record) => {
    const data = {
      id: record.id,
      title: record.title,
      code_short_description: record.shortDescription,
      description: record.description,
      redeemable_period: [
        dayjs(record.startDate).set("seconds", 0),
        dayjs(record.endDate).set("seconds", 0),
      ],
      defaultLocaleId: record.defaultLocaleId,
      locales: record.locales,
      status: record.status,
      surveyQuestions: record.surveyQuestions,
      show_images_only: record.showImagesOnly,
      membership_required: record.membershipRequired,
      active_date: record.activeDate
        ? dayjs(record.activeDate).set("seconds", 0)
        : dayjs().set("seconds", 0),
    };
    const { primaryCategoryId, subCategoryId } =
      getMerchantCategoryAndSubCategory(merchantCategories, record.categoryId);
    data.primary_category = primaryCategoryId;
    data.subcategory = subCategoryId;
    setCoverImage(record.previewResourceUrl);
    const tempArray = [];
    record.locales.forEach((locale) => {
      tempArray.push(locale?.previewResourceUrl || "");
    });
    setLocalisedCoverImages(tempArray);
    setEditData(data);
    form.setFieldsValue(data);
    setIsOpen(true);
    setIsEdit(true);
  };

  // useMemo is used to prevent the re-creation of the 'columns' variable on every render.
  const columns = useMemo(
    () => [
      {
        dataIndex: "previewResourceUrl",
        width: 100,
        render: (_, record) => (
          <img
            src={
              record?.locales?.find?.(
                (locale) => locale.localeId === currentLocale
              )?.previewResourceUrl ??
              record?.previewResourceUrl ??
              DefaultImage
            }
            alt="Preview"
            height={50}
            width={50}
            style={{ borderRadius: 5, objectFit: "cover" }}
          />
        ),
      },
      {
        title: t("ID"),
        dataIndex: "id",
        key: "id",
      },
      {
        title: t("NAME_COLUMN"),
        dataIndex: "title",
        key: "title",
        width: "30%",
        sorter: (a, b) => a.title.length - b.title.length,
        render: (_, { title, locales }) =>
          locales?.find?.((locale) => locale.localeId === currentLocale)
            ?.title ?? title,
      },
      {
        title: t("SWEEPSTAKES.ACTIVE_DATE_COLUMN"),
        key: "activeDate",
        dataIndex: "activeDate",
        render: (_, { activeDate }) => (
          <>
            <Tag isDisabled>
              {activeDate ? dayjs(activeDate).format("MM/DD/YYYY") : "--"}
            </Tag>
          </>
        ),
      },
      {
        title: t("REDEEMABLE_PERIOD").toUpperCase(),
        key: "startDate",
        dataIndex: "startDate",
        render: (_, { startDate, endDate }) => (
          <>
            {
              <>
                <Tag isDisabled>{dayjs(startDate).format("MM/DD/YYYY")}</Tag>
                <span>-</span>
                <Tag isDisabled style={{ marginLeft: 5 }}>
                  {dayjs(endDate).format("MM/DD/YYYY")}
                </Tag>
              </>
            }
          </>
        ),
      },
      {
        title: t("ACTION"),
        key: "action",
        width: "15%",
        render: (_, record) => (
          <Space size="middle">
            <Button
              type="link"
              style={{ height: "auto" }}
              onClick={() => handleEditClick(record)}
            >
              <Tooltip title={t("EDIT")}>
                <Micons icon="edit" className="table-action-icon" />
              </Tooltip>
            </Button>
            <Popconfirm
              title={t("SURVEY.CONFIRM_DELETE")}
              onConfirm={() => handleDelete(record.id)}
              onCancel={() => {}}
              okText={t("YES")}
              cancelText={t("NO")}
            >
              <Button type="link" style={{ height: "auto" }}>
                <Tooltip title={t("DELETE")}>
                  <Micons icon="delete" className="table-action-icon" />
                </Tooltip>
              </Button>
            </Popconfirm>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "notifications",
                    label: t("NOTIFICATIONS"),
                    icon: (
                      <Micons
                        icon="notifications"
                        className="table-action-icon"
                      />
                    ),
                  },
                ],
                onClick: (el) => {
                  if (el.key === "notifications") {
                    setCurrentRecord(record);
                    setIsNotificationsModalOpen(true);
                  }
                },
              }}
              style={{ width: 200 }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Micons icon="more_vert" className="table-action-icon" />
              </a>
            </Dropdown>
            ,
          </Space>
        ),
      },
    ],
    [t]
  );

  // Switching between a list view and a grid view.
  const onViewChange = () => {
    setDisplayType(displayType === "list" ? "grid" : "list");
  };

  const handleCancleClick = () => {
    form.resetFields();
    setIsOpen(false);
    setEditData(null);
    setIsEdit(null);
    setIsDataLoading(false);
    setIsSubmitting(false);
    setCoverImage("");
    setLocalisedCoverImages([]);
  };

  const handleFormSubmit = async (data) => {
    const { surveyQuestions, surveyQuestionLocales } = data;
    const surveyQuestionsArray = surveyQuestions.map((question, index) => {
      return {
        ...question,
        options: question.options.map(({ option }) => option),
        locales: surveyQuestionLocales?.map((locale) => {
          const { localeId, questions } = locale;
          return {
            localeId,
            question: questions[index].question,
            options: questions[index].options.map(({ option }) => option),
          };
        }),
      };
    });
    setIsSubmitting(true);
    data?.locales?.forEach((locale, index) => {
      if (isEdit) {
        delete locale.benefitId;
      }
      if (localisedCoverImages?.[index]) {
        locale.previewResourceUrl = localisedCoverImages?.[index];
      } else {
        delete locale.previewResourceUrl;
      }
    });
    const finalData = {
      title: data.title,
      shortDescription: data.code_short_description,
      description: data.description,
      startDate: dayjs(data.redeemable_period[0]).set("seconds", 0).format(),
      endDate: dayjs(data.redeemable_period[1]).set("seconds", 0).format(),
      resourceUrl: coverImage,
      previewResourceUrl: coverImage,
      type: "survey",
      status: data.status,
      multipleResource: false,
      locales: data.locales,
      defaultLocaleId: data.defaultLocaleId,
      surveyQuestions: surveyQuestionsArray,
      membershipRequired: true,
      showImagesOnly: data.show_images_only,
      activeDate: dayjs(data.active_date).set("seconds", 0),
      categoryId:
        data.subcategory && data.subcategory !== 0
          ? data.subcategory
          : data.primary_category,
    };
    const createNewEbook = isEdit
      ? await updateBenefitToDb(finalData, data.id)
      : await addBenefitToDb(finalData);
    if (createNewEbook?.status === 200) {
      if (isEdit) {
        setData((prev) =>
          prev.map((item) => (item.id === data.id ? createNewEbook.data : item))
        );
      } else {
        setData((prev) => [createNewEbook.data, ...prev]);
      }
      setIsOpen(false);
      setIsEdit(false);
      setCoverImage("");
    } else {
      message.error(
        isEdit ? t("BENEFIT_UPDATE_ERROR") : t("BENEFIT_CREATE_ERROR")
      );
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <PageHeader
        title={t("SURVEY.SURVEY")}
        breadcrumbs={BREADCRUMB_SURVEY(t)}
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              form.setFieldValue(
                "primary_category",
                getCategoryIdFromName(merchantCategories, "Archive")
              ); //Default archive
              setIsOpen(true);
            }}
          >
            {t("SURVEY.ADD")}
          </Button>
        }
      />
      <DiscountCodesFilters
        onViewChange={onViewChange}
        type={displayType}
        onSearch={handleSearch}
        onFilterByChange={handleFilterByChange}
      />
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {data.length === 0 ? (
            <NoData />
          ) : (
            <>
              {displayType === "list" ? (
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.id}
                  locale={{
                    triggerDesc: t("SORT_DESCENDING"),
                    triggerAsc: t("SORT_ASCENDING"),
                    cancelSort: t("CANCEL_SORT"),
                  }}
                />
              ) : (
                <Row gutter={[25, 25]}>
                  {data.map((item) => {
                    const currentLocaleData = item?.locales?.find(
                      (locale) => locale.localeId === currentLocale
                    );
                    return (
                      <Col
                        xs={24}
                        md={12}
                        lg={6}
                        key={`${item.title}-${item.couponCode}}`}
                      >
                        <Card
                          isActiveDate
                          cover={
                            <img
                              alt={item.title}
                              src={
                                currentLocaleData?.previewResourceUrl ??
                                item.previewResourceUrl
                              }
                              height="200px"
                              width="100%"
                              className="pointer"
                              onClick={() => handleEditClick(item)}
                            />
                          }
                          actions={[
                            <Popconfirm
                              title={t("SURVEY.CONFIRM_DELETE")}
                              onConfirm={() => handleDelete(item.id)}
                              onCancel={() => {}}
                              okText={t("YES")}
                              cancelText={t("NO")}
                            >
                              <Button type="link">
                                <Tooltip title={t("DELETE")}>
                                  <Micons
                                    icon="delete"
                                    className="table-action-icon"
                                  />
                                </Tooltip>
                              </Button>
                            </Popconfirm>,
                            <Button
                              type="link"
                              onClick={() => handleEditClick(item)}
                            >
                              <Tooltip title={t("EDIT")}>
                                <Micons
                                  icon="edit"
                                  className="table-action-icon"
                                />
                              </Tooltip>
                            </Button>,
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: "notifications",
                                    label: t("NOTIFICATIONS"),
                                    icon: (
                                      <Micons
                                        icon="notifications"
                                        className="table-action-icon"
                                      />
                                    ),
                                  },
                                ],
                                onClick: (el) => {
                                  if (el.key === "notifications") {
                                    setCurrentRecord(item);
                                    setIsNotificationsModalOpen(true);
                                  }
                                },
                              }}
                              style={{ width: 200 }}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Micons
                                  icon="more_vert"
                                  className="table-action-icon"
                                />
                              </a>
                            </Dropdown>,
                          ]}
                        >
                          <p style={{ marginBottom: 5 }}>{`${t("ID")}: ${item.id}`}</p>
                          <div className="active-date">
                            <strong>{`${t(
                              "SWEEPSTAKES.ACTIVE_DATE_COLUMN"
                            )}:`}</strong>
                            {dayjs(item.activeDate).format("MM/DD/YYYY")}
                          </div>
                          <Tag className={Styles.tag} isDisabled>
                            {`${dayjs(item.startDate).format(
                              "MM/DD/YYYY"
                            )} - ${dayjs(item.endDate).format("MM/DD/YYYY")}`}
                          </Tag>
                          <Meta
                            title={currentLocaleData?.title ?? item.title}
                            description={
                              <ReadMore
                                text={
                                  currentLocaleData?.description ||
                                  item?.description
                                }
                                characterLimit={86}
                              >
                                {(currentLocaleData?.description?.length ||
                                  item?.description.length) > 86 && (
                                  <ReadMore.Trigger
                                    trigger={`...${t("MORE")}`}
                                    text={
                                      currentLocaleData?.description ||
                                      item?.description
                                    }
                                  />
                                )}
                              </ReadMore>
                            }
                          />
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </>
          )}
        </>
      )}
      <NewSurvey
        form={form}
        isOpen={isOpen}
        loading={isSubmitting}
        isEdit={isEdit}
        editData={editData}
        onSubmitClick={() => form.submit()}
        onCancel={handleCancleClick}
        onSubmit={handleFormSubmit}
        isDataLoading={isDataLoading}
        setCoverImage={setCoverImage}
        coverImage={coverImage}
        localisedCoverImages={localisedCoverImages}
        setLocalisedCoverImages={setLocalisedCoverImages}
      />
      <NotificationsModal
        isOpen={isNotificationsModalOpen}
        currentRecord={currentRecord}
        onCancel={() => {
          setCurrentRecord(null);
          setIsNotificationsModalOpen(false);
        }}
      />
    </>
  );
};

export default Survey;

import React, { useState, useEffect } from "react";
import { useProfileContext } from "../context/profile.context";
import { Modal, Button, Form } from "react-bootstrap";
import { getRoleIds } from "../../utils/rolesHelper";
import { createUser, updtaeUser } from "../../pages/profile/apiUtils";
import SearchableDropdown from "../dropdown/dropdown.searchable";
import { getAllMerchantsFromDb } from "../../pages/launchpad/merchant/apiUtils.js";

const RoleCheckboxes = ({
  role,
  level,
  parentChecked,
  selectedRoleIds,
  setSelectedRoleIds,
  validated,
}) => {
  const checked = selectedRoleIds.findIndex((id) => id === role.id) >= 0;
  const handleCheck = (e) => {
    const subRoleIds = getRoleIds(role).slice(1);
    if (e.target.checked) {
      setSelectedRoleIds((prev) => {
        // Remove any sub roles that might already be selected
        const filtered = prev.filter((id) => !subRoleIds.includes(id));
        // Add the newly checked role to selected
        return [...filtered, role.id];
      });
    } else {
      // Remove role from selected
      setSelectedRoleIds((prev) => prev.filter((id) => id != role.id));
    }
  };
  return (
    <>
      <Form.Check
        className="font-weight-normal"
        key={role?.id}
        type={"checkbox"}
        id={role?.id}
        label={role?.name}
        style={{ paddingLeft: `${30 + 15 * level}px` }}
        onChange={handleCheck}
        required={validated && selectedRoleIds.length === 0}
        disabled={parentChecked}
        checked={checked}
      />
      {role?.children.map((child) => (
        <RoleCheckboxes
          key={child.id}
          role={child}
          level={level + 1}
          parentChecked={checked || parentChecked}
          selectedRoleIds={selectedRoleIds}
          setSelectedRoleIds={setSelectedRoleIds}
          validated={validated}
        />
      ))}
    </>
  );
};

export default function CreateUserModal({
  show,
  hide,
  addUser,
  updateUser,
  selectedUserData,
  setSelectedUserData
}) {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [selectedRoleIds, setSelectedRoleIds] = useState([]);
  const [validated, setValidated] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchantIds, setSelectedMerchantIds] = useState([]);

  // get All merchants initially
  useEffect(() => {
    const fetchMerchants = async () => {
      const getAllMerchants = await getAllMerchantsFromDb();
      if (getAllMerchants?.status === 200) {
        setMerchants(() =>
          getAllMerchants.data.map((item) => {
            return {
              name: item.name,
              id: item.id,
            };
          })
        );
      }
    };

    let mounted = true;
    if (mounted) {
      fetchMerchants();
    }
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (selectedUserData) {
      setName(selectedUserData.name);
      setEmail(selectedUserData.email);
      setSelectedRoleIds(selectedUserData.roles);
      setSelectedMerchantIds(selectedUserData.merchant)
    }
  }, [selectedUserData]);

  const { roleTree } = useProfileContext();

  const closeModal = () => {
    setName(null);
    setEmail(null);
    setPhone(null);
    setSelectedRoleIds([]);
    setSelectedUserData(null);
    setValidated(false);
    hide();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false || selectedRoleIds.length == 0) {
      setValidated(true);
      return;
    }

    if (!selectedUserData) {
      createUser(name, email, phone, selectedRoleIds, selectedMerchantIds).then(
        (response) => {
          addUser(response.data);
        }
      );
    } else {
      updtaeUser(selectedUserData?.id, name, email, phone, selectedRoleIds, selectedMerchantIds).then((response) => {
        updateUser();
      });
    }

    setValidated(false);
    closeModal();
  };

  return (
    <Modal
      centered
      backdrop={true}
      show={show}
      onHide={closeModal}
      className="modalStyle"
    >
      <Form
        className="text-black"
        onSubmit={onSubmit}
        noValidate
        validated={validated}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-black">
            {!selectedUserData ? "Create New User" : "Update User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email address.
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              We will send an email invite to the email provided.
            </Form.Text>
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="formBasicPhone">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                            type="tel"
                            placeholder="Phone number (optional)"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                            If a phone number is provided, a user can access scan functionality using sms login.
                        </Form.Text>
                    </Form.Group> */}
          <Form.Group className="mb-3" controlId="formBasicRoles">
            <Form.Label>Roles</Form.Label>
            <RoleCheckboxes
              role={roleTree}
              level={0}
              parentChecked={false}
              selectedRoleIds={selectedRoleIds}
              setSelectedRoleIds={setSelectedRoleIds}
              validated={validated}
            />
            <Form.Text className="text-muted">
              Select one or more roles for this user.
            </Form.Text>
            {validated && selectedRoleIds.length == 0 && (
              <Form.Text className="text-danger font-weight-bold">
                Please select at least one role.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicMerchants">
            <Form.Label>Merchants</Form.Label>

            <SearchableDropdown
              options={merchants}
              searchPrompt="Search for a merchant"
              emptyPrompt="No merchants available."
              selected={selectedMerchantIds}
              setSelected={setSelectedMerchantIds}
            />
            <Form.Text className="text-muted">
              Select one or more merchants for this user (optional).
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btnSecondary" onClick={hide}>
            Cancel
          </Button>
          <Button className="btnPrimary" type="submit">
            Create New User
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

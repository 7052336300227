import { MERCHANT_CATEGORY_ICONS } from "./merchants/constant";

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const checkUserRolePermissions = (
  currentUserRole,
  permissionIdToCheck
) => {
  if (!currentUserRole?.rolePermissions) {
    return false;
  }
  return currentUserRole.rolePermissions.find(
    (rolePermission) =>
      rolePermission.permissionsMetadata.name === permissionIdToCheck
  );
};

export const hasRequiredPermissions = (
  userPermissions,
  requiredPermissions
) => {
  // If no permissions are required, grant access
  if (requiredPermissions?.length === 0) {
    return true;
  }

  // Otherwise, check if the user has any of the required permissions
  return !!userPermissions?.find((permission) =>
    requiredPermissions?.includes(permission?.permissionsMetadata?.name)
  );
};

export const getFileExtension = (imageUrl) => {
  const fileExtension = imageUrl.split(".").pop().toLowerCase();
  return fileExtension;
};

export const capitalizeLetter = (letter) => {
  return `${letter.charAt(0).toUpperCase()}${letter.slice(1, letter.length)}`;
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const getIconForMerchantCategory = (categories, id) => {
  let categoryId = 0;
  for (const category of categories) {
    if (category.id === id) {
      categoryId = category.id;
      break;
    }
  }

  if (categoryId === 0) {
    for (const category of categories) {
      for (const subCategory of category.subCategories) {
        if (subCategory.id === id) {
          categoryId = subCategory.parentCategoryId;
          break;
        }
      }
    }
  }
  return MERCHANT_CATEGORY_ICONS[categoryId];
};

export const getLocalStorage = (type) => {
  return localStorage.getItem(type);
};

export const getRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

export const getMerchantCategoryAndSubCategory = (categories, categoryId) => {
  let primaryCategoryId = null,
    subCategoryId = null,
    name = "";
  for (const category of categories) {
    if (category.id === categoryId) {
      primaryCategoryId = category.id;
      name = category.name;
      break;
    }
  }
  if (!primaryCategoryId) {
    for (const category of categories) {
      for (const subCategory of category.subCategories) {
        if (subCategory.id === categoryId) {
          primaryCategoryId = subCategory.parentCategoryId; //ont change this
          subCategoryId = subCategory.id;
          name = subCategory.name;
          break;
        }
      }
    }
  }
  return { primaryCategoryId, subCategoryId, name };
};

export const getCategoryIdFromName = (categories, categoryName) => {
  let categoryId = null;
  for (const category of categories) {
    if (category.name.toLowerCase() === categoryName.toLowerCase()) {
      categoryId = category.id;
      break;
    }
  }

  if (!categoryId) {
    for (const subCategory of categories.subCategories) {
      if (subCategory.name === categoryName.toLowerCase()) {
        categoryId = subCategory.id;
        break;
      }
    }
  }

  return categoryId;
};

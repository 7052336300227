// import Third party and React
import { useEffect, useMemo, useState } from "react";
import {
  Space,
  Row,
  Col,
  Skeleton,
  Form,
  Popconfirm,
  Tooltip,
  Dropdown,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// import utils, API Utils and helper functions
import { BREADCRUMB_TOKEN_GATED_AUDIO } from "../../../../utils/benefits/constant";
import {
  deleteBenefitFromDb,
  getAllBenefitsFromDb,
  addBenefitToDb,
  updateBenefitToDb,
} from "../apiUtils";
import {
  debounce,
  getCategoryIdFromName,
  getMerchantCategoryAndSubCategory,
} from "../../../../utils/helper";

// import custom components
import {
  PageHeader,
  Button,
  Table,
  Tag,
  Card,
  NoData,
  Micons,
} from "../../../../components/customAntd";
import DiscountCodesFilters from "./filters";
import NewVideo from "./newAudio";
import ReadMore from "../../../../components/readmore";
import MediaPlayer from "../../../../components/mediaPlayer";
import { useAppContext } from "../../../../components/context/app.context";
import DefaultImage from "../../../../assets/images/default-img.jpg";
import NotificationsModal from "../sweepstakes/notifications";

// import custom Style
import Styles from "./styles/gatedAudio.module.scss";

const { Meta } = Card;

const GatedAudio = () => {
  const [form] = Form.useForm();
  const [displayType, setDisplayType] = useState("grid");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [mediaPlayerOpen, setMediaPlayerOpen] = useState(false);
  const [audio, setAudio] = useState([]);
  const [allowDownload, setAllowDownload] = useState(false);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [filters, setFilters] = useState({
    filterCondition: { benefitType: "audio" },
  });
  const { t } = useTranslation();
  const { currentLocale, merchantCategories } = useAppContext();

  // useMemo is used to prevent the re-creation of the 'columns' variable on every render.
  const columns = useMemo(
    () => [
      {
        dataIndex: "previewResourceUrl",
        width: 100,
        render: (_, record) => (
          <img
            src={
              record?.locales?.find?.(
                (locale) => locale.localeId === currentLocale
              )?.previewResourceUrl ??
              record?.previewResourceUrl ??
              DefaultImage
            }
            alt="Preview"
            height={50}
            width={50}
            className="table-data-image"
            onClick={() => handleEditClick(record)}
          />
        ),
      },
      {
        title: t("ID"),
        dataIndex: "id",
        key: "id",
      },
      {
        title: t("AUDIO.NAME_COLUMN"),
        dataIndex: "title",
        key: "title",
        width: "30%",
        sorter: (a, b) => a.title.length - b.title.length,
        render: (_, { title, locales }) =>
          locales?.find?.((locale) => locale.localeId === currentLocale)
            ?.title ?? title,
      },
      {
        title: t("TYPE"),
        dataIndex: "multipleResource",
        key: "multipleResource",
        render: (_, record) =>
          record.multipleResource
            ? `${t("AUDIO.ALBUM")}/${t("PLAYLIST")}`
            : t("AUDIO.SINGLE"),
      },
      {
        title: t("ACTIVE_DATE").toUpperCase(),
        key: "activeDate",
        dateIndex: "activeDate",
        render: (_, { activeDate }) => (
          <>
            <Tag isDisabled>
              {activeDate
                ? dayjs(activeDate).format("MM/DD/YYYY hh:mm A")
                : "--"}
            </Tag>
          </>
        ),
      },
      {
        title: t("ACTION"),
        key: "action",
        width: "15%",
        render: (_, record) => (
          <Space size="middle">
            <Button type="link">
              <Tooltip title={t("EDIT")}>
                <Micons
                  icon="edit"
                  className="table-action-icon"
                  onClick={() => handleEditClick(record)}
                />
              </Tooltip>
            </Button>
            <Popconfirm
              title={t("AUDIO.CONFIRM_DELETE")}
              onConfirm={() => handleDelete(record.id)}
              onCancel={() => {}}
              okText={t("YES")}
              cancelText={t("NO")}
              placement="topRight"
              arrowPointAtCenter
            >
              <Button type="link">
                <Tooltip title={t("DELETE")}>
                  <Micons icon="delete" className="table-action-icon" />
                </Tooltip>
              </Button>
            </Popconfirm>
            <Button
              type="link"
              onClick={() => {
                setMediaPlayerOpen(true);
                setAudio(record?.BenefitResource);
                setAllowDownload(record?.allowDownload);
              }}
            >
              <Micons icon="play_circle" className="table-action-icon" />
            </Button>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "notifications",
                    label: t("NOTIFICATIONS"),
                    icon: (
                      <Micons
                        icon="notifications"
                        className="table-action-icon"
                      />
                    ),
                  },
                ],
                onClick: (el) => {
                  if (el.key === "notifications") {
                    setCurrentRecord(record);
                    setIsNotificationsModalOpen(true);
                  }
                },
              }}
              style={{ width: 200 }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Micons icon="more_vert" className="table-action-icon" />
              </a>
            </Dropdown>
            ,
          </Space>
        ),
      },
    ],
    [t]
  );

  // Switching between a list view and a grid view.
  const onViewChange = () => {
    setDisplayType(displayType === "list" ? "grid" : "list");
  };

  // Get all Videos initially
  useEffect(() => {
    const fetchCodes = async () => {
      setIsLoading(true);
      const getAllVideos = await getAllBenefitsFromDb(filters);
      if (getAllVideos?.status === 200) {
        setData(getAllVideos.data);
      }
      setIsLoading(false);
    };
    fetchCodes();
  }, [filters]);

  const handleCancleClick = () => {
    form.resetFields();
    setIsOpen(false);
    setEditData(null);
    setIsEdit(null);
  };

  // Submitting the final form after it has passed all of the validation checks
  const handleFormSubmit = async (
    data,
    resources,
    heroImage,
    heroImages,
    submitSuccess
  ) => {
    setIsSubmitting(true);
    data?.locales?.forEach((locale, index) => {
      if (isEdit) {
        delete locale.benefitId;
      }
      if (heroImages?.[index]) {
        locale.previewResourceUrl = heroImages?.[index]?.[0]?.resourceUrl;
      } else {
        delete locale.previewResourceUrl;
      }
    });
    const finalData = {
      multipleResource: data.benefit_type === "single_audio" ? false : true,
      title: data.album_title,
      shortDescription: data.code_short_description,
      description: data.album_description,
      allowDownload: data.allow_download ? true : false,
      resources: resources,
      previewResourceUrl: heroImage[0].resourceUrl,
      resourceUrl: heroImage[0].resourceUrl,
      status: data.status,
      type: "audio",
      locales: data.locales,
      defaultLocaleId: data.defaultLocaleId,
      membershipRequired: true,
      showImagesOnly: data.show_images_only,
      activeDate: dayjs(data.active_date).set("seconds", 0),
      categoryId:
        data.subcategory && data.subcategory !== 0
          ? data.subcategory
          : data.primary_category,
    };
    const createBenefit = isEdit
      ? await updateBenefitToDb(finalData, data.id)
      : await addBenefitToDb(finalData);
    if (createBenefit?.status === 200) {
      if (isEdit) {
        setData((prev) =>
          prev.map((item) => (item.id === data.id ? createBenefit.data : item))
        );
      } else {
        setData((prev) => [createBenefit.data, ...prev]);
      }
      handleCancleClick();
      submitSuccess();
    }
    setIsSubmitting(false);
  };

  // Handler for the edit 'video' button
  const handleEditClick = (record) => {
    const finalData = {
      id: record.id,
      benefit_type: record.multipleResource ? "playlist" : "single_audio",
      album_title: record.title,
      code_short_description: record.shortDescription,
      album_description: record.description,
      status: record.status,
      allow_download: !!record.allowDownload,
      locales: record.locales,
      defaultLocaleId: record.defaultLocaleId,
      membership_required: true,
      show_images_only: record.showImagesOnly,
      active_date: record.activeDate
        ? dayjs(record.activeDate).set("seconds", 0)
        : dayjs().set("seconds", 0),
    };
    const { primaryCategoryId, subCategoryId } =
      getMerchantCategoryAndSubCategory(merchantCategories, record.categoryId);
    finalData.primary_category = primaryCategoryId;
    finalData.subcategory = subCategoryId;

    setEditData(record);
    setIsEdit(true);
    form.setFieldsValue(finalData);
    setIsOpen(true);
  };

  // Handler for the delete 'video' button
  const handleDelete = async (recordId) => {
    const deletedCode = await deleteBenefitFromDb(recordId);
    if (deletedCode?.status === 200) {
      setData((prev) => prev.filter((item) => item.id !== deletedCode.data.id));
    }
  };

  // Handler for the search input
  const handleSearch = debounce((e) => {
    const searchTerm = e.target.value;
    if (searchTerm !== "") {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            search: searchTerm,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.search;
      setFilters(filtersCopy);
    }
  });

  // Handler for the filter select
  const handleFilterByChange = (value) => {
    if (value) {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            status: value,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.status;
      setFilters(filtersCopy);
    }
  };

  return (
    <>
      <PageHeader
        title={t("AUDIO.HEADER")}
        breadcrumbs={BREADCRUMB_TOKEN_GATED_AUDIO(t)}
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              form.setFieldValue(
                "primary_category",
                getCategoryIdFromName(merchantCategories, "Archive")
              ); //Default archive
              setIsOpen(true);
            }}
          >
            {t("AUDIO.ADD_BENEFIT")}
          </Button>
        }
      />
      <DiscountCodesFilters
        onViewChange={onViewChange}
        type={displayType}
        onSearch={handleSearch}
        onFilterByChange={handleFilterByChange}
      />
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {data.length === 0 ? (
            <NoData />
          ) : (
            <>
              {displayType === "list" ? (
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.id}
                  locale={{
                    triggerDesc: t("SORT_DESCENDING"),
                    triggerAsc: t("SORT_ASCENDING"),
                    cancelSort: t("CANCEL_SORT"),
                  }}
                />
              ) : (
                <Row gutter={[25, 25]}>
                  {data.map((item) => {
                    const currentLocaleData = item?.locales?.find(
                      (locale) => locale.localeId === currentLocale
                    );
                    return (
                      <Col
                        xs={24}
                        md={12}
                        lg={6}
                        key={`${item.title}-${item.couponCode}}`}
                      >
                        <Card
                          isActiveDate
                          cover={
                            <img
                              alt={item.title}
                              src={
                                currentLocaleData?.previewResourceUrl ??
                                item.previewResourceUrl
                              }
                              height="200px"
                              width="100%"
                              className="pointer"
                              onClick={() => handleEditClick(item)}
                            />
                          }
                          actions={[
                            <Popconfirm
                              title={t("AUDIO.CONFIRM_DELETE")}
                              onConfirm={() => handleDelete(item.id)}
                              onCancel={() => {}}
                              okText={t("YES")}
                              cancelText={t("NO")}
                            >
                              <Tooltip title={t("DELETE")}>
                                <Micons
                                  icon="delete"
                                  className="table-action-icon"
                                />
                              </Tooltip>
                            </Popconfirm>,
                            <Tooltip title={t("EDIT")}>
                              <Button
                                type="link"
                                onClick={() => handleEditClick(item)}
                              >
                                <Micons
                                  icon="edit"
                                  className="table-action-icon"
                                />
                              </Button>
                            </Tooltip>,
                            <Button
                              type="link"
                              onClick={() => {
                                setMediaPlayerOpen(true);
                                setAudio(item?.BenefitResource);
                                setAllowDownload(item?.allowDownload);
                              }}
                            >
                              <Micons
                                icon="play_circle"
                                className="table-action-icon"
                              />
                            </Button>,
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: "notifications",
                                    label: t("NOTIFICATIONS"),
                                    icon: (
                                      <Micons
                                        icon="notifications"
                                        className="table-action-icon"
                                      />
                                    ),
                                  },
                                ],
                                onClick: (el) => {
                                  if (el.key === "notifications") {
                                    setCurrentRecord(item);
                                    setIsNotificationsModalOpen(true);
                                  }
                                },
                              }}
                              style={{ width: 200 }}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Micons
                                  icon="more_vert"
                                  className="table-action-icon"
                                />
                              </a>
                            </Dropdown>,
                          ]}
                        >
                          <p style={{ marginBottom: 5 }}>{`${t("ID")}: ${item.id}`}</p>
                          <div className="active-date">
                            <strong>{`${t(
                              "SWEEPSTAKES.ACTIVE_DATE_COLUMN"
                            )}:`}</strong>
                            {dayjs(item.activeDate).format("MM/DD/YYYY")}
                          </div>
                          {/* <Tag className={Styles.tag} isDisabled>
                            {dayjs(item.createdAt).format("MM/DD/YYYY")}
                          </Tag> */}
                          <Meta
                            title={currentLocaleData?.title ?? item.title}
                            description={
                              <ReadMore
                                text={
                                  currentLocaleData?.description ??
                                  item?.description
                                }
                                characterLimit={78}
                              >
                                {(currentLocaleData?.description.length ||
                                  item?.description.length) > 78 && (
                                  <ReadMore.Trigger
                                    trigger={`...${t("MORE")}`}
                                    text={
                                      currentLocaleData?.description ??
                                      item?.description
                                    }
                                  />
                                )}
                              </ReadMore>
                            }
                          />
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </>
          )}
          <NotificationsModal
            isOpen={isNotificationsModalOpen}
            currentRecord={currentRecord}
            onCancel={() => {
              setCurrentRecord(null);
              setIsNotificationsModalOpen(false);
            }}
          />
        </>
      )}

      <NewVideo
        form={form}
        isOpen={isOpen}
        loading={isSubmitting}
        isEdit={isEdit}
        editData={editData}
        onSubmitClick={() => form.submit()}
        onCancel={handleCancleClick}
        onSubmit={handleFormSubmit}
      />
      <MediaPlayer
        isOpen={mediaPlayerOpen}
        onCancel={() => {
          setMediaPlayerOpen(false);
          setAudio([]);
        }}
        mediaFiles={audio}
        isDownloadable={allowDownload}
        bodyStyle={{}}
        isAudio={true}
        width={500}
      />
    </>
  );
};

export default GatedAudio;

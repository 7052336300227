import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

import constant from "./../config/constant";

const baseURL = constant.config.REACT_API_HOST;

const initialState = {
  user: {},
  UserList: [],
  success: "",
  error: "",
  loading: false,
  userAuditHistoryList: [],
};

const showErrorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};



export const addUser = createAsyncThunk(
  "user/addUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseURL}/users/create`, data);
      // showSuccessToast("Success: User registered!");
      return response.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const login = createAsyncThunk(
  "user/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseURL}users/login/`, data);
      return response.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const getByEmail = createAsyncThunk(
  "user/getByEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseURL}users/select-by-email/${data.email}/${data.userId}`);
      return response.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async (data, { rejectWithValue }) => {
    try {
      let response;
      response = await axios.post(`${baseURL}admin/users`, data);
      return response.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadLoggedInUser = createAsyncThunk(
  "user/loadLoggedInUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}profiles/me`);
      return response.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data, { rejectWithValue }) => {
    try {
      let response;
      let updateResponse;
      if ("imageToUpdate" in data) {
        if (!data.deleteImage) {
          const formData = new FormData();
          formData.append("fileFor", data.fileFor);
          formData.append("uploadIpfs", data.isUploadIpfs);
          formData.append("file", data.image);
          response = await axios.post(`${baseURL}uploads`, formData);
        }
        updateResponse = await axios.put(`${baseURL}/profiles/me`, {
          [`${data.imageToUpdate}`]: !data.deleteImage ? response.data.s3Url : null,
          ...data
        });
      } else {
        updateResponse = await axios.put(
          `${baseURL}profiles/me`,
          data
        );
      }
      return updateResponse.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);
export const getUserById = createAsyncThunk(
  "user/getUserById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/users/select/${data.id}`);
      return response.data;
    } catch (error) {
      showErrorToast(
        "Error: Something went wrong. Please contact admin!. " + error.message
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [addUser.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [addUser.fulfilled]: (state, action) => {
      return {
        ...state,
        user: action.payload,
        success: "success",
        error: "",
        loading: false,
      };
    },
    [addUser.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [getByEmail.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [getByEmail.fulfilled]: (state, action) => {
      return {
        ...state,
        user: action.payload,
        success: "success",
        error: "",
        loading: false,
      };
    },
    [getByEmail.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [getUsers.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [getUsers.fulfilled]: (state, action) => {
      return {
        ...state,
        UserList: action.payload,
        success: "success",
        error: "",
        loading: false,
      };
    },
    [getUsers.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [updateUser.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [updateUser.fulfilled]: (state, action) => {
      return {
        ...state,
        user: action.payload,
        ...state.user,
        success: "success",
        error: "",
        loading: false,
      };
    },
    [updateUser.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [login.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [login.fulfilled]: (state, action) => {
      return {
        ...state,
        user: action.payload,
        success: "success",
        error: "",
        loading: false,
      };
    },
    [login.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [getUserById.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [getUserById.fulfilled]: (state, action) => {
      return {
        ...state,
        userAuditHistoryList: [...state.userAuditHistoryList, action.payload],
        success: "success",
        error: "",
        loading: false,
      };
    },
    [getUserById.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [loadLoggedInUser.rejected]: (state, action) => {
      return {
        ...state,
        success: "reject",
        error: action.payload,
        loading: false,
      };
    },
    [loadLoggedInUser.pending]: (state, action) => {
      return { ...state, success: "pending", error: "", loading: true };
    },
    [loadLoggedInUser.fulfilled]: (state, action) => {
      return {
        ...state,
        user: action.payload,
        currentRoleId: action.payload.roles[0].id,
        success: "success",
        error: "",
        loading: false,
      };
    },
  },
});

export default userSlice.reducer;

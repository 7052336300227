import { useTranslation } from "react-i18next";
import { Row, Col, Form, Input, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import { Button } from "../../../../components/customAntd";
import { FormWrapper } from "../../../../components/customAntd";
import { updateStorefront } from "../apiUtils";

const items = [
  "buttonPrimaryColor",
  "loginButtonsPrimaryColor",
  "benefitModalButtonPrimaryColor",
  "benefitModalButtonPrimaryColorDisabled",
  "benefitModalButtonSecondaryColor",
  "benefitModalCouponBackground",
  "benefitModalTitleColor",
  "benefitModalDisclaimerBackgroundColor",
  "benefitModalTitleFontFamily",
  "benefitModalDescriptionFontFamily",
  "benefitModalTitleFontSize",
  "benefitModalDescriptionFontSize",
  "benefitModalDescriptionTextColor",
  "benefitModalButtonTextColor",
  "benefitModalCouponFontFamily",
  "benefitModalCouponDiscountFontSize",
  "benefitModalCouponExpiryFontSize",
  "loginButtonsFontFamily",
  "loginButtonsFontSize",
  "loginButtonsFontSizeSmall",
  "benefitModalDisclaimerTitleFontSize",
  "benefitModalDisclaimerContentFontSize",
  "benefitModalMerchantContainerBorderColor",
  "benefitModalMerchantContainerBackgroundColor",
  "benefitModalMerchantContainerFontFamily",
  "benefitModalMerchantContainerButtonColor",
  "footerHeaderFontFamily",
  "footerHeaderFontSize",
  "footerHeaderFontColor",
  "footerDescriptionFontFamily",
  "footerDescriptionFontSize",
  "footerDescriptionFontColor",
  "footerCopyrightFontFamily",
  "footerCopyrightFontSize",
  "footerCopyrightFontColor",
  "footerBackgroundColor",
  "categoryHeadingFontFamily",
  "categoryHeadingFontSize",
  "categoryHeadingFontColor",
  "categorySeeAllFontFamily",
  "categorySeeAllFontSize",
  "categorySeeAllFontColor",
  "benefitCardTitleFontFamily",
  "benefitCardTitleFontSize",
  "benefitCardTitleFontColor",
  "benefitCardDescriptionFontFamily",
  "benefitCardDescriptionFontSize",
  "benefitCardDescriptionFontColor",
  "userDetailsFontFamily",
  "userDetailsFontSize",
  "benefitsHeadingFontFamily",
  "benefitsHeadingFontSize",
  "benefitsHeadingFontColor",
  "formLabelsFontFamily",
  "formLabelFontColor",
  "newsNotificationFontFamily",
  "newsNotificationTitleFontColor",
  "newsNotificationTimeStampFontColor",
  "newsNotificationMessageFontColor",
  "newsNotificationActiveTabBackgroundColor",
  "benefitCardShortDescriptionFontColor",
];

const { Item } = Form;

const EditTheme = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { store } = location.state;
  const { t } = useTranslation();
  const initialValues = {};

  const formatLabel = (label) => {
    // Function to format the label based on your requirements
    const formattedLabel = label
      .replace(/([A-Z])/g, " $1") // Insert a space before each uppercase letter
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    return formattedLabel;
  };

  const handleSubmit = async (data) => {
    const finalData = {
      ...store,
      categoryImages: store?.categoryImages?.map(({ url, categoryId }) => ({
        categoryId,
        url,
      })),
      heroImages: store?.heroImages?.map((el) => {
        return {
          imageUrl: el.imageUrl,
          startDate: el?.startDate,
          endDate: el?.endDate,
          status: el?.status,
          redirectUrl: el?.redirectUrl || null,
        };
      }),
      themeDetails: data,
    };
    const response = await updateStorefront(finalData, store.id);
    if (response.status !== 200) {
      message.error(
        `${t("STOREFRONT_UI.SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`
      );
    } else {
      navigate("/storefront-builder");
    }
  };

  // Map theme details to form field names
  items.forEach((item) => {
    initialValues[item] = store.themeDetails?.[item];
  });

  return (
    <>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        // disabled={loading || isDataLoading}
        initialValues={initialValues}
        requiredMark={false}
      >
        <FormWrapper heading={t("EDIT THEME")}>
          <Row gutter={52}>
            {items.length &&
              items.map((item) => {
                const formattedLabel = formatLabel(item);
                // Check if the item includes "color" or "background"
                const isColorPicker =
                  item.toLowerCase().includes("color") ||
                  item.toLowerCase().includes("background");
                return (
                  <Col xs={24} md={12}>
                    <Item label={formattedLabel} name={item}>
                      {isColorPicker ? (
                        <Input type="color" style={{ width: "80px" }} />
                      ) : (
                        <Input />
                      )}
                    </Item>
                  </Col>
                );
              })}
          </Row>
          <Row>
            <Col>
              <Button
                htmlType="submit"
                //loading={isLoading}
              >
                {t("UPDATE THEME")}
              </Button>
            </Col>
          </Row>
        </FormWrapper>
      </Form>
    </>
  );
};

export default EditTheme;

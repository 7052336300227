import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import { BenefitsCard, PageHeader } from "../../../components/customAntd";
import { BREADCRUMB_ANNOUNCEMENT } from "../../../utils/announcements/constant";

const Announcement = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader
        title={t("ANNOUNCEMENT.ANNOUNCEMENT")}
        breadcrumbs={BREADCRUMB_ANNOUNCEMENT(t)}
      />
      <Row gutter={[40, 40]}>
        <Col xs={24} md={8}>
          <BenefitsCard
            icon="notifications"
            title={t("ANNOUNCEMENT.NOTIFICATIONS")}
            description={t("ANNOUNCEMENT.NOTIFICATION_DESCRIPTION")}
            link="/announcement/notifications"
          />
        </Col>
        <Col xs={24} md={8}>
          <BenefitsCard
            icon="newspaper"
            title={t("ANNOUNCEMENT.PRESS_RELEASE")}
            description={t("ANNOUNCEMENT.PRESS_RELEASE_DESCRIPTION")}
            link="/announcement/pressrelease"
          />
        </Col>
        <Col xs={24} md={8}>
          <BenefitsCard
            icon="feed"
            title={t("NEWS")}
            description={t("ANNOUNCEMENT.NEWS_DESCRIPTION")}
            link="/announcement/news"
          />
        </Col>
      </Row>
    </>
  );
};

export default Announcement;

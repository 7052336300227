import { useEffect, useState } from "react";
import { Col, Form, Row, message } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";

import { useAppContext } from "../../../../components/context/app.context";
import { BREADCRUMB_CREATE_STOREFRONT } from "../../../../utils/storeFront/constant";
import { getLocalesFromDb } from "../../../../utils/common/apiUtils";
import { addStorefront, updateStorefront } from "../apiUtils";
import { Button, PageHeader } from "../../../../components/customAntd";
import NotFound from "../../../../components/notFound";
import {
  CommercialTemplateName,
  AnimeTemplateName,
} from "../../../../utils/constants";
import { BENEFITS_LIST } from "../../../../utils/benefits/constant";
// import StoreTemplate from "../../collections/createStorefront/storeTemplate";
import { getEmailTemplatesForStorefront } from "../../../../utils/utils";
import { getMerchantCategoryAndSubCategory } from "../../../../utils/helper";
import { MENU_OPTIONS } from "../../../../utils/storeFront/constant";

import MenuOptions from "./menuOptions";
import OverrideOptions from "./overrideOptions";
import BenefitAndCollectionSort from "./benefitAndCollectionSort";
import EmailTemplates from "./emailTemplates";
import Footer from "./footer";
import LoyaltyProgram, { LoyaltyProgramModal } from "./loyaltyProgram";
import WaysToEarnPoints, { WaysToEarnPointsModal } from "./waysToEarnPoints";
import MarketingSplashScreen from "./marketingSplashScreen";
import LanguageOptions from "./languageOptions";
import LoginOptions from "./loginOptions";
import HeroSection from "./heroSection";
import Logo from "./logo";

const BuildStoreFront = () => {
  const [form] = Form.useForm();
  const [pointsForm] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isEdit,
    store,
    themeId,
    // collections,
    programId,
    tiers,
    defaultChain,
  } = location.state;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);
  const [logoImage, setLogoImage] = useState("");
  const [heroImage, setHeroImage] = useState("");
  const [heroImages, setHeroImages] = useState([]);
  const [categoryImages, setCategoryImages] = useState([]);
  const [isCategoryImageLoading, setIsCategoryImageLoading] = useState(false);
  const [isLogoImageLoading, setIsLogoImageLoading] = useState(false);
  const [isHeroImageLoading, setIsHeroImageLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [availableLocales, setAvailableLocales] = useState([]);
  const [availableLocalesRewards, setAvailableLocalesRewards] = useState([]);
  const [availableLocalesPoints, setAvailableLocalesPoints] = useState([]);
  const [defaultLocale] = useState("en_US");
  const [defaultLoginMethod] = useState("EmailAndSMS");
  const availableLoginMethods = [
    {
      value: "Email",
      label: "Email",
    },
    {
      value: "SMS",
      label: "SMS",
    },
    {
      value: "EmailAndSMS",
      label: "Email And SMS",
    },
  ];

  const [isLoyaltyProgramModalOpen, setIsLoyaltyProgramModalOpen] =
    useState(false);
  const [isPointsTableModalOpen, setIsPointsTableModalOpen] = useState(false);
  const [marketingData, setMarketingData] = useState([]);
  const [marketingDataLocales, setMarketingDataLocales] = useState([]);
  const [pointsMarketingData, setPointsMarketingData] = useState([]);
  const [pointsMarketingDataLocales, setPointsMarketingDataLocales] = useState(
    []
  );
  const [localizedLogoImages, setLocalizedLogoImages] = useState([]);
  const [localizedLogoImagesLoading, setLocalizedLogoImagesLoading] = useState(
    []
  );
  const { merchantCategories } = useAppContext();

  const [categoriesList, setCategoriesList] = useState();
  const [benefitsList, setBenefitsList] = useState(BENEFITS_LIST(t));
  const watchEnableFooter = Form.useWatch("enableFooter", form);
  const watchDefaultLanguage = Form.useWatch("defaultLocaleId", form);
  const watchLocales = Form.useWatch("locales", form);
  const watchLocalesRewards = Form.useWatch("rewardLocales", pointsForm);
  const watchLocalesPoints = Form.useWatch("pointLocales", pointsForm);
  const watchMarketingData = Form.useWatch("marketingData", pointsForm);
  const watchPointsMarketingData = Form.useWatch("pointsData", pointsForm);
  const watchEnableProgramTable = Form.useWatch("enableProgramTable", form);
  const watchEnablePointsTable = Form.useWatch("enablePointsTable", form);
  const watchBenefitSortBy = Form.useWatch("benefitSortBy", form);
  const watchEnableCustomTemplates = Form.useWatch(
    "useCustomMailTemplate",
    form
  );

  useEffect(() => {
    /* to populate categories list state with categoryId 
    and not id that we get from the categories list from the backend*/
    if (merchantCategories.length) {
      setCategoriesList(
        merchantCategories?.map((el, index) => ({
          categoryId: el.id,
          name: el.name,
          position: index + 1,
          sortBy: "CreatedDate",
          sortOrder: "asc",
        }))
      );
    }
  }, [merchantCategories]);

  useEffect(() => {
    form.setFieldValue(
      "benefitSortCriteria",
      (watchBenefitSortBy === "BenefitType"
        ? benefitsList
        : categoriesList
      )?.map((el, index) => ({
        categoryId: el.categoryId,
        name:
          watchBenefitSortBy === "BenefitType"
            ? t(`BENEFIT_SORT.${el.name.toUpperCase()}`)
            : t(el.name.toUpperCase()),
        position: index + 1,
        sortBy: el.sortBy || "CreatedDate",
        sortOrder: el.sortOrder || "asc",
      }))
    );
  }, [watchBenefitSortBy, benefitsList, categoriesList, t]);

  useEffect(() => {
    // To set form data and other states for images
    form.setFieldsValue({
      programId,
      ...(isEdit && {
        ...store,
        membershipCriteriaOverride: store.membershipCriteria !== null,
        showImageOnlyOverride: store.showImageOnly !== null,
      }),
      loginMethod: isEdit
        ? store.themeId === CommercialTemplateName &&
          themeId !== CommercialTemplateName
          ? defaultLoginMethod
          : store.loginMethod
        : "",
      heroImages: isEdit
        ? store?.heroImages?.map((el) => ({
            range: [
              el?.startDate ? dayjs(el?.startDate) : undefined,
              el?.endDate ? dayjs(el?.endDate) : undefined,
            ],
            status: el.status,
            redirectUrl: el.redirectUrl || null,
          }))
        : [{}],
    });

    if (store) {
      setLocalizedLogoImages(store.locales.map((locale) => locale?.logoUrl));
      setLogoImage(store?.logoUrl);
      setHeroImage(store?.heroImageUrl);
      setCategoryImages(
        store?.benefitSortCriteria?.map((el) => {
          return (
            store?.categoryImages?.find(
              (innerEl) => innerEl?.categoryId === el?.categoryId
            )?.url || ""
          );
        })
      );
      //Settting the categoryImages state in order of the benefitSortCriteria

      // setHeroImages([]);
      setHeroImages(store?.heroImages?.map((el) => el.imageUrl));
      const rewardLocalesArray = [];
      const marketingDataArray = store.marketingData.map((item) => {
        if (item?.locales.length > 0) {
          item.locales.forEach((el, index) => {
            if (
              rewardLocalesArray?.find((rel) => rel.localeId === el.localeId)
            ) {
              rewardLocalesArray[index].rewards.push({ reward: el.reward });
            } else {
              rewardLocalesArray.push({
                localeId: el.localeId,
                rewards: [{ reward: el.reward }],
              });
            }
          });
        }
        return {
          reward: item.reward,
          tiers: item.tiers.map((tier) => tier.tierId),
        };
      });

      setMarketingData(marketingDataArray);
      setMarketingDataLocales(rewardLocalesArray);
      const pointLocalesArray = [];
      const pointsMarketingDataArray = store.pointsMarketingData.map((item) => {
        if (item?.locales.length > 0) {
          item.locales.forEach((el, index) => {
            if (
              pointLocalesArray?.find((rel) => rel.localeId === el.localeId)
            ) {
              pointLocalesArray[index].waysToEarn.push({
                wayToEarn: el.wayToEarn,
              });
            } else {
              pointLocalesArray.push({
                localeId: el.localeId,
                waysToEarn: [{ wayToEarn: el.wayToEarn }],
              });
            }
          });
        }
        return { wayToEarn: item.wayToEarn, pointValue: item.pointValue };
      });
      setPointsMarketingData(pointsMarketingDataArray);
      pointsForm.setFieldsValue({
        ...(isEdit && {
          marketingData: marketingDataArray,
          rewardLocales: rewardLocalesArray,
          pointsData: pointsMarketingDataArray,
          pointLocales: pointLocalesArray,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, store]);

  // Get all locales
  useEffect(() => {
    const getLocales = async () => {
      const data = await getLocalesFromDb();
      if (data) {
        const localesData = data.map((locale) => {
          return {
            value: locale.localeId,
            label: `${locale.language.language} (${locale.country.country})`,
            isSelected: isEdit
              ? !!store?.locales?.find((localeEl) => {
                  return localeEl.localeId === locale.localeId;
                }) || store.defaultLocaleId === locale.localeId
              : locale.localeId === defaultLocale,
          };
        });
        setAvailableLocales(localesData);
        if (isEdit) {
          setAvailableLocalesRewards(
            localesData.map((locale) => {
              return {
                ...locale,
                isSelected:
                  !!store?.marketingData?.find((item) => {
                    return item?.locales[0]?.localeId === locale?.value;
                  }) || store?.defaultLocaleId === locale?.value,
              };
            })
          );
          setAvailableLocalesPoints(
            localesData.map((locale) => {
              return {
                ...locale,
                isSelected:
                  !!store?.pointsMarketingData?.find((item) => {
                    return item?.locales[0]?.localeId === locale?.value;
                  }) || store?.defaultLocaleId === locale?.value,
              };
            })
          );
        } else {
          setAvailableLocalesRewards(localesData);
          setAvailableLocalesPoints(localesData);
        }
      }
    };
    getLocales();
  }, [defaultLocale, isEdit]);

  useEffect(() => {
    if (watchMarketingData?.length > 0) {
      setMarketingData(watchMarketingData);
    }
  }, [watchMarketingData]);

  useEffect(() => {
    if (watchPointsMarketingData?.length > 0) {
      setPointsMarketingData(watchPointsMarketingData);
    }
  }, [watchPointsMarketingData]);

  useEffect(() => {
    if (!isEdit) {
      form.setFieldsValue({
        enablePointsTable: false,
        enableProgramTable: false,
        showProgramName: false,
        benefitSortBy: "BenefitType",
      });
    } else {
      form.setFieldsValue({
        verifyEmailMailTemplate: store.useCustomMailTemplate
          ? store.verifyEmailMailTemplate
          : null,
        resetPasswordMailTemplate: store.useCustomMailTemplate
          ? store.resetPasswordMailTemplate
          : null,
        locales: store.locales.map((item) => ({
          ...item,
          verifyEmailMailTemplate: store.useCustomMailTemplate
            ? item.verifyEmailMailTemplate
            : null,
          resetPasswordMailTemplate: store.useCustomMailTemplate
            ? item.resetPasswordMailTemplate
            : null,
        })),
        hiddenMenuItems: MENU_OPTIONS(t)
          .filter((el) => !store?.hiddenMenuItems?.includes(el.value))
          .map((el) => el.value),
      });
      if (store.benefitSortBy === "BenefitType") {
        if (!store?.benefitSortCriteria) {
          setBenefitsList(
            benefitsList.map((el, index) => ({
              name: el.name,
              position: index + 1,
              sortBy: "CreatedDate",
              sortOrder: "asc",
            }))
          );
        } else {
          setBenefitsList(
            store?.benefitSortCriteria?.map((el) => ({
              ...el,
              name: BENEFITS_LIST(t).find(
                (innerEl) => innerEl.type === el.benefitType
              ).name,
            }))
          );
        }
        //default categories in this case are being set from another useEffect at the top
      } else {
        if (store?.benefitSortCriteria) {
          setCategoriesList(
            store.benefitSortCriteria.map((el) => {
              return {
                ...el,
                /*Only category id is being sent to backend so the name 
            needs to be fetched from the list of categories
            */
                name: getMerchantCategoryAndSubCategory(
                  merchantCategories,
                  el.categoryId
                ).name,
              };
            })
          );
        }
        setBenefitsList(
          benefitsList.map((el, index) => ({
            name: el.name,
            position: index + 1,
            sortBy: "CreatedDate",
            sortOrder: "asc",
          }))
        );
      }
    }
  }, [isEdit, merchantCategories]);
  // Updated locals state if someone changed language in the form
  const updateLocales = (value, prevValue, list) => {
    const update = (item) => {
      return item.value === value
        ? {
            ...item,
            isSelected: true,
          }
        : {
            ...item,
            isSelected: item.value === prevValue ? false : item.isSelected,
          };
    };

    if (list === "storefront") {
      setAvailableLocales((prev) => {
        return prev.map((item) => update(item));
      });
    } else if (list === "rewards") {
      setAvailableLocalesRewards((prev) => {
        return prev.map((item) => update(item));
      });
    } else if (list === "points") {
      setAvailableLocalesPoints((prev) => {
        return prev.map((item) => update(item));
      });
    } else if (list === "defaultLanguage") {
      setAvailableLocales((prev) => {
        return prev.map((item) => update(item));
      });

      setAvailableLocalesRewards((prev) => {
        return prev.map((item) => update(item));
      });
      setAvailableLocalesPoints((prev) => {
        return prev.map((item) => update(item));
      });
    }
  };

  const onFinish = async (data) => {
    if (watchEnableProgramTable && !marketingData?.length) {
      message.error(t("REWARDS_ERROR"));
      return;
    }

    const marketingDataArray = marketingData?.map((item, index) => {
      return {
        programId: programId,
        reward: item.reward,
        position: index,
        ...(marketingDataLocales?.length > 0 && {
          locales: marketingDataLocales?.map((localeEl) => {
            return {
              localeId: localeEl.localeId,
              reward: localeEl.rewards[index].reward,
            };
          }),
        }),
        tiers: item.tiers.map((tier) => {
          return {
            tierId: tier,
            enabled: true,
          };
        }),
      };
    });
    const pointsMarketingDataArray = pointsMarketingData
      ?.filter((item) => item.wayToEarn)
      .map((item, index) => {
        return {
          programId: programId,
          wayToEarn: item.wayToEarn,
          pointValue: item.pointValue,
          position: index,
          ...(pointsMarketingDataLocales?.length > 0 && {
            locales: pointsMarketingDataLocales?.map((localeEl) => {
              return {
                localeId: localeEl.localeId,
                wayToEarn: localeEl.waysToEarn[index].wayToEarn,
              };
            }),
          }),
        };
      });
    data?.locales?.forEach((locale, index) => {
      setIsSubmitting(true);
      locale.logoUrl = localizedLogoImages[index];
      locale.storefrontId = undefined;
      locale.verifyEmailMailTemplate = data.useCustomMailTemplate
        ? locale.verifyEmailMailTemplate
        : getEmailTemplatesForStorefront(locale.localeId).verifyEmailTemplate;
      locale.resetPasswordMailTemplate = data.useCustomMailTemplate
        ? locale.resetPasswordMailTemplate
        : getEmailTemplatesForStorefront(locale.localeId)
            .resetPasswordMailTemplate;
    });

    /* When sorting by Benefit Type only benefitType field
    is required and not the category Id and vice versa for sorting
    with Categories. 
    */
    const categoryImagesArray = [];
    if (data.benefitSortBy === "BenefitType") {
      data.benefitSortCriteria = data?.benefitSortCriteria?.map(
        ({ categoryId, name, ...rest }, index) => {
          if (categoryImages[index]) {
            categoryImagesArray.push({
              categoryId,
              url: categoryImages[index],
            });
          }
          return {
            ...rest,
            benefitType: BENEFITS_LIST(t)?.find?.((el) => el.name === name)
              .type,
          };
        }
      );
    } else {
      data.benefitSortCriteria = data?.benefitSortCriteria?.map(
        ({ name, categoryId, ...rest }, index) => {
          if (categoryImages[index]) {
            categoryImagesArray.push({
              categoryId,
              url: categoryImages[index],
            });
          }
          return { categoryId, ...rest };
        }
      );
    }

    const finalData = {
      defaultChain: themeId === CommercialTemplateName ? "" : defaultChain,
      programId: programId,
      logoUrl: logoImage,
      title: data.title,
      heroHeadline: data.heroHeadline,
      heroDesc: data.heroDesc,
      heroBenefitsHeadline: data.heroBenefitsHeadline,
      showBenefitsCarousel: data.showBenefitsCarousel,
      heroBenefitsDescription: data.heroBenefitsDescription,
      heroImageUrl: heroImage,
      defaultLocaleId: data.defaultLocaleId,
      enableFooter: data.enableFooter,
      companyName: data.companyName,
      footerText: data.footerText,
      websiteUrl: data.websiteUrl,
      twitterUrl: data.twitterUrl,
      discordUrl: data.discordUrl,
      mediumUrl: data.mediumUrl,
      locales: data.locales,
      headerText: data.headerText || "", // set nr on be
      bodyText: data.bodyText || "", // setnr on be
      themeId: themeId,
      loginMethod: data.loginMethod || "Default",
      preRegisterUser: !!data.preRegisterUser,
      pointsTableHeaderText: data.pointsTableHeaderText || "",
      pointsTableBodyText: data.pointsTableBodyText || "",
      marketingHeadline: data.marketingHeadline,
      marketingDesc: data.marketingDesc,
      useCustomMailTemplate: data.useCustomMailTemplate,
      verifyEmailMailTemplate: data.useCustomMailTemplate
        ? data.verifyEmailMailTemplate
        : getEmailTemplatesForStorefront(data.defaultLocaleId)
            .verifyEmailTemplate,
      resetPasswordMailTemplate: data.useCustomMailTemplate
        ? data.resetPasswordMailTemplate
        : getEmailTemplatesForStorefront(data.defaultLocaleId)
            .resetPasswordMailTemplate,
      marketingData: marketingDataArray,
      pointsMarketingData: pointsMarketingDataArray,
      enableProgramTable: data.enableProgramTable,
      enablePointsTable: data.enablePointsTable,
      rememberMeInDays: data.rememberMeInDays,
      defaultSmsCountryCode: data.defaultSmsCountryCode,
      smsSupportedCountries: data.smsSupportedCountries,
      benefitSortBy: data.benefitSortBy,
      showProgramName: data.showProgramName,
      benefitSortCriteria: data.benefitSortCriteria,
      hiddenMenuItems: MENU_OPTIONS(t)
        .filter((el) => !data.hiddenMenuItems.includes(el.value))
        .map((el) => el.value),
      membershipCriteria: data.membershipCriteriaOverride
        ? data.membershipCriteria
        : null,
      showImageOnly: data.showImageOnlyOverride ? data.showImageOnly : null,
      heroImages: data?.heroImages?.map((el, index) => {
        return {
          imageUrl: heroImages[index],
          startDate: el?.range?.[0]?.format() || null,
          endDate: el?.range?.[1]?.format() || null,
          status: el?.status,
          redirectUrl: el?.redirectUrl || null,
        };
      }),
      categoryImages: categoryImagesArray,
    };

    const response = isEdit
      ? await updateStorefront(finalData, store.id)
      : await addStorefront(finalData);
    if (response.data.code === "ER-1001") {
      //Error for already existing title
      form.setFields([
        {
          name: "title",
          value: data.title,
          errors: [response.data.message],
        },
      ]);
      form.scrollToField("title", { block: "center" });
    } else if (response.status !== 200) {
      message.error(
        `${t("STOREFRONT_UI.SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`
      );
    } else {
      navigate("/storefront-builder");
    }

    setIsSubmitting(false);
  };

  const handleLoyaltyProgramForm = (data) => {
    setMarketingData([...data?.marketingData]);
    if (data?.rewardLocales?.length > 0)
      setMarketingDataLocales([...data.rewardLocales]);
    setIsLoyaltyProgramModalOpen(false);
  };

  const handlePointsTableForm = (data) => {
    setPointsMarketingData([...data?.pointsData]);
    if (data?.pointLocales?.length > 0)
      setPointsMarketingDataLocales([...data.pointLocales]);
    setIsPointsTableModalOpen(false);
  };

  const swap = (array, destination, source) => {
    // To swap draggable items
    const temp = array[destination];
    array[destination] = array[source];
    array[source] = temp;
  };

  const handleRewardDragEnd = (data) => {
    const { destination, source } = data;
    if (!!destination) {
      const rewardsArray = [...pointsForm.getFieldValue("marketingData")];
      const rewardsLocaleArray = [...watchLocalesRewards];
      swap(rewardsArray, destination.index, source.index);
      pointsForm.setFieldsValue({
        marketingData: rewardsArray,
        rewardLocales: rewardsLocaleArray.map((localeEl) => {
          swap(localeEl.rewards, destination.index, source.index);
          return {
            ...localeEl,
          };
        }),
      });
    }
  };

  const handlePointsDragEnd = (data) => {
    const { destination, source } = data;
    if (!!destination) {
      const pointsArray = [...pointsForm.getFieldValue("pointsData")];
      const pointsLocaleArray = [...watchLocalesPoints];
      swap(pointsArray, destination.index, source.index);
      pointsForm.setFieldsValue({
        pointsData: pointsArray,
        pointLocales: pointsLocaleArray.map((localeEl) => {
          swap(localeEl.waysToEarn, destination.index, source.index);
          return {
            ...localeEl,
          };
        }),
      });
    }
  };

  return (
    <>
      <PageHeader
        title={t("STOREFRONT_UI.BUILD_STORE")}
        breadcrumbs={BREADCRUMB_CREATE_STOREFRONT(isEdit, t)}
        wrapperStyle={{ marginBottom: 40 }}
      />

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError={{ block: "center" }}
        requiredMark={false}
        disabled={isLoading}
      >
        <Row gutter={[32]}>
          <Col lg={13}>
            <Logo
              t={t}
              themeId={themeId}
              isEdit={isEdit}
              logoImage={logoImage}
              setLogoImage={setLogoImage}
              setIsLogoImageLoading={setIsLogoImageLoading}
            />
            <LoginOptions
              t={t}
              themeId={themeId}
              defaultLoginMethod={defaultLoginMethod}
              availableLoginMethods={availableLoginMethods}
            />
            <MenuOptions t={t} />
            <OverrideOptions t={t} />
            <HeroSection
              t={t}
              defaultLocale={defaultLocale}
              availableLocales={availableLocales}
              updateLocales={updateLocales}
              watchDefaultLanguage={watchDefaultLanguage}
              themeId={themeId}
              heroImage={heroImage}
              setIsHeroImageLoading={setIsHeroImageLoading}
              setHeroImage={setHeroImage}
              heroImages={heroImages}
              setHeroImages={setHeroImages}
            />
            <BenefitAndCollectionSort
              themeId={themeId}
              t={t}
              setBenefitsList={setBenefitsList}
              setCategoriesList={setCategoriesList}
              swap={swap}
              // collections={collections}
              categoryImages={categoryImages}
              setCategoryImages={setCategoryImages}
              setIsCategoryImageLoading={setIsCategoryImageLoading}
              watchBenefitSortBy={watchBenefitSortBy}
            />

            {themeId === AnimeTemplateName && (
              <>
                <LoyaltyProgram
                  t={t}
                  watchEnableProgramTable={watchEnableProgramTable}
                  setIsLoyaltyProgramModalOpen={setIsLoyaltyProgramModalOpen}
                />
                <WaysToEarnPoints
                  t={t}
                  watchEnablePointsTable={watchEnablePointsTable}
                  setIsPointsTableModalOpen={setIsPointsTableModalOpen}
                />
                <MarketingSplashScreen t={t} />
              </>
            )}
            <Footer t={t} watchEnableFooter={watchEnableFooter} />
            <EmailTemplates
              themeId={themeId}
              t={t}
              watchEnableCustomTemplates={watchEnableCustomTemplates}
            />
            <LanguageOptions
              t={t}
              themeId={themeId}
              availableLocales={availableLocales}
              watchLocales={watchLocales}
              updateLocales={updateLocales}
              localizedLogoImages={localizedLogoImages}
              setLocalizedLogoImages={setLocalizedLogoImages}
              setLocalizedLogoImagesLoading={setLocalizedLogoImagesLoading}
              watchEnableFooter={watchEnableFooter}
              watchEnableCustomTemplates={watchEnableCustomTemplates}
              setAvailableLocales
            />

            <Button
              htmlType="submit"
              loading={isSubmitting}
              disabled={
                isLogoImageLoading ||
                isHeroImageLoading ||
                isCategoryImageLoading ||
                localizedLogoImagesLoading.includes(true)
              }
            >
              {isEdit
                ? t("STOREFRONT_UI.UPDATE_STORE")
                : t("STOREFRONT_UI.CREATE")}
            </Button>
          </Col>
        </Row>
      </Form>
      {themeId === AnimeTemplateName && (
        <>
          <LoyaltyProgramModal
            t={t}
            onCancel={() => setIsLoyaltyProgramModalOpen(false)}
            onSubmit={handleLoyaltyProgramForm}
            isLoyaltyProgramModalOpen={isLoyaltyProgramModalOpen}
            pointsForm={pointsForm}
            tiers={tiers}
            handleRewardDragEnd={handleRewardDragEnd}
            watchMarketingData={watchMarketingData}
            watchLocalesRewards={watchLocalesRewards}
            availableLocalesRewards={availableLocalesRewards}
            setAvailableLocalesRewards={setAvailableLocalesRewards}
            marketingData={marketingData}
            updateLocales={updateLocales}
          />
          <WaysToEarnPointsModal
            t={t}
            isPointsTableModalOpen={isPointsTableModalOpen}
            onCancel={() => setIsPointsTableModalOpen(false)}
            onSubmit={handlePointsTableForm}
            pointsForm={pointsForm}
            handlePointsDragEnd={handlePointsDragEnd}
            pointsMarketingData={pointsMarketingData}
            watchLocalesPoints={watchLocalesPoints}
            watchPointsMarketingData={watchPointsMarketingData}
            availableLocalesPoints={availableLocalesPoints}
            setAvailableLocalesPoints={setAvailableLocalesPoints}
            updateLocales={updateLocales}
            setAvailableLocales={setAvailableLocales}
          />
        </>
      )}
    </>
  );
};

export default BuildStoreFront;

import { useEffect, useState } from "react";
import { Col, message, Popconfirm, Row, Select, Space, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../../components/context/app.context";
import {
  Card,
  PageHeader,
  Button,
  Micons,
  NoData,
} from "../../../components/customAntd";
import CopyToClipboard from "../../../components/copyToClipboard";
import { BREADCRUMB_STOREBUILDER } from "../../../utils/storeFront/constant";
import {
  getAllStorefrontList,
  deleteStorefront,
  updateStorefront,
  unarchiveStorefront,
} from "./apiUtils";
import DeleteConfirmation from "../../../components/deleteConfirmation";
import constant from "../../../config/constant";
import MemberIdCsvUpload from "./memberIdCsvUpload";
import DeleteUser from "./deleteUser";
import Styles from "./styles/storefrontBuilder.module.scss";

const StorefrontBuilder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [storeForFileUpload, setStoreForFileUpload] = useState(null);
  const [selectedStoreForDelete, setSelectedStoreForDelete] = useState(null);
  const [storeForDeletingUsers, setStoreForDeletingUsers] = useState(null);
  const [isArchivedFilter, setIsArchivedFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [data, setData] = useState([]);
  const { currentLocale } = useAppContext();

  // Get all storefront
  useEffect(() => {
    const fetchStoreFronts = async () => {
      setIsLoading(true);
      const getAllStores = await getAllStorefrontList({
        limit: 50,
        offset: 0,
        filterCondition: {
          isArchived: isArchivedFilter,
        },
      });
      if (getAllStores?.status === 200) {
        setData(getAllStores.data);
      }
      setIsLoading(false);
    };
    fetchStoreFronts();
  }, [isArchivedFilter]);

  const handleDelete = async (id = selectedStoreForDelete?.id) => {
    const response = await deleteStorefront(id);
    if (response.status === 200) {
      message.success({
        content: t("STOREFRONT_UI.DELETED_SUCCESSFULLY"),
        duration: 2,
        key: "success",
      });
      setData((prev) => {
        return prev.filter((item) => item.id !== response.data.id);
      });
    } else {
      message.error({
        content: `${t("STOREFRONT_UI.DELETE_ERROR")}.${t("TRY_AGAIN_LATER")}`,
        key: "error",
        duration: 2,
      });
    }
    setSelectedStoreForDelete(null);
  };

  const handleArchiveActions = async (store) => {
    setDisableButtons(store.id);
    let response;
    if (store.isArchived) {
      response = await unarchiveStorefront(store?.id);
    } else {
      const dataToUpdate = { ...store };
      dataToUpdate.collections = store.collections.map(
        ({ collectionId, position }) => ({
          collectionId,
          position,
        })
      );
      dataToUpdate.isArchived = !store.isArchived;
      delete dataToUpdate.program;
      response = await updateStorefront(dataToUpdate, store?.id);
    }
    if (response.status === 200) {
      message.success({
        content: store.isArchived
          ? t("STOREFRONT_UI.UNARCHIVE_SUCCESSFUL")
          : t("STOREFRONT_UI.ARCHIVE_SUCCESSFUL"),
        duration: 2,
        key: "success",
      });
      setData((prev) => {
        return prev.filter((item) => item.id !== response.data.id);
      });
    } else {
      message.error({
        content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
        duration: 2,
        key: "error",
      });
    }
    setDisableButtons(null);
  };

  const handleEditClick = async (store) => {
    navigate("/storefront-builder/select-program", {
      state: {
        isEdit: true,
        store: store,
      },
    });
  };

  const handleEditTheme = async (store) => {
    navigate("/storefront-builder/edit-theme", {
      state: {
        isEdit: true,
        store: store,
      },
    });
  };

  const storefrontUrl = () =>
    constant.config.STOREFRONT_SITE_URL || "https://store.faro.xyz/store/";

  return (
    <>
      <MemberIdCsvUpload
        isOpen={!!storeForFileUpload}
        onClose={() => {
          setStoreForFileUpload(null);
        }}
        store={storeForFileUpload}
      />
      <DeleteConfirmation
        title={
          selectedStoreForDelete?.locales?.find((locale) => {
            return locale.localeId === currentLocale;
          })?.title ?? selectedStoreForDelete?.title
        }
        onConfirm={handleDelete}
        onCancel={() => setSelectedStoreForDelete(null)}
        isOpen={!!selectedStoreForDelete}
        text={t("STOREFRONT_UI.DELETE_CONFIRMATION_TEXT")}
      />
      <DeleteUser
        isOpen={!!storeForDeletingUsers}
        onClose={() => {
          setStoreForDeletingUsers(null);
        }}
        store={storeForDeletingUsers}
      />
      <PageHeader
        title={t("STOREFRONT_UI.BUILD_STORE")}
        breadcrumbs={BREADCRUMB_STOREBUILDER(t)}
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/storefront-builder/select-program", {
                state: { isEdit: false },
              });
            }}
          >
            {t("STOREFRONT_UI.NEW_STORE")}
          </Button>
        }
      />
      <Select
        options={[
          { label: "Unarchived", value: false },
          { label: "Archived", value: true },
        ]}
        onChange={(val) => {
          setIsArchivedFilter(val);
        }}
        value={isArchivedFilter}
        style={{ width: 200, marginBottom: 40 }}
      />
      <Row gutter={[0, 30]}>
        {data.length > 0 ? (
          data?.map((store) => {
            return (
              <Col sm={24}>
                <Card
                  bodyStyle={{ padding: 0 }}
                  style={{ boxShadow: "0px 15px 40px rgba(9,9,9,0.09)" }}
                >
                  <Row>
                    <Col sm={6}>
                      <img src={store.heroImageUrl} alt="" width="100%" />
                    </Col>
                    <Col sm={18}>
                      <Card
                        className={Styles.card}
                        actions={[
                          ...(!store.isArchived
                            ? [
                                <Tooltip title={t("EDIT THEME")}>
                                  <Button
                                    type="link"
                                    onClick={() => handleEditTheme(store)}
                                    disabled={!!disableButtons}
                                  >
                                    <Micons
                                      icon="color_lens"
                                      className="table-action-icon"
                                    />
                                  </Button>
                                </Tooltip>,
                                <Tooltip title={t("EDIT")}>
                                  <Button
                                    type="link"
                                    onClick={() => handleEditClick(store)}
                                    disabled={!!disableButtons}
                                  >
                                    <Micons
                                      icon="edit"
                                      className="table-action-icon"
                                    />
                                  </Button>
                                </Tooltip>,
                                <Tooltip title={t("DELETE")}>
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      setSelectedStoreForDelete(store)
                                    }
                                    disabled={
                                      !!disableButtons ||
                                      store?.FCMtopics?.length > 0
                                    }
                                  >
                                    <Micons
                                      icon="delete"
                                      className="table-action-icon"
                                    />
                                  </Button>
                                </Tooltip>,
                                <Tooltip title={t("FAQ.TITLE")}>
                                  <Button
                                    type="link"
                                    disabled={!!disableButtons}
                                    onClick={() =>
                                      navigate(
                                        `/storefront-builder/${store.id}/faqs`
                                      )
                                    }
                                  >
                                    {t("STOREFRONT_UI.FAQ")}
                                  </Button>
                                </Tooltip>,
                                ...(store?.preRegisterUser
                                  ? [
                                      <Tooltip title="Upload member id file">
                                        <Button
                                          type="link"
                                          onClick={() =>
                                            setStoreForFileUpload(store)
                                          }
                                        >
                                          <Micons icon="file_upload" />
                                        </Button>
                                      </Tooltip>,
                                      <Tooltip
                                        title={`${t("MANAGE_USER")} ${t(
                                          "FOR"
                                        )} ${store.title}`}
                                      >
                                        <Button
                                          type="link"
                                          onClick={() => {
                                            setStoreForDeletingUsers(store);
                                          }}
                                        >
                                          <Micons icon="folder_delete" />
                                        </Button>
                                      </Tooltip>,
                                    ]
                                  : []),
                              ]
                            : []),
                          <Popconfirm
                            title={
                              store.isArchived
                                ? t("STOREFRONT_UI.UNARCHIVE_CONFIRMATION")
                                : t("STOREFRONT_UI.ARCHIVE_CONFIRMATION")
                            }
                            onConfirm={() => {
                              handleArchiveActions(store);
                            }}
                            onCancel={() => {}}
                            okText={t("YES")}
                            cancelText={t("NO")}
                          >
                            <Tooltip
                              title={
                                store.isArchived ? t("UNARCHIVE") : t("ARCHIVE")
                              }
                            >
                              <Button
                                type="link"
                                loading={disableButtons === store?.id}
                                disabled={!!disableButtons}
                              >
                                <Micons
                                  icon="archive"
                                  className="table-action-icon"
                                />
                              </Button>
                            </Tooltip>
                          </Popconfirm>,
                        ]}
                      >
                        {!store.isArchived && (
                          <CopyToClipboard
                            tooltipText={t(
                              "STOREFRONT_UI.PUBLIC_STOREFRONT_PAGE_LINK"
                            )}
                            copyText={`${storefrontUrl()}${store.title}`}
                          />
                        )}
                        <h2>{`${t("STOREFRONT")}: ${store.id} - ${
                          store.title
                        }`}</h2>
                        <h3>{`${t("PROGRAM")}:`}</h3>
                        <p>
                          {store?.program?.locales?.find((locale) => {
                            return locale.localeId === currentLocale;
                          })?.name || store?.program?.name}
                        </p>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            );
          })
        ) : (
          <NoData />
        )}
      </Row>
    </>
  );
};
export default StorefrontBuilder;

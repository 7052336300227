import { useState } from "react";
import {
  Form,
  Radio,
  Row,
  Col,
  Collapse,
  Select,
  Popconfirm,
} from "antd";
import { CaretRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import {
  Button,
  FormWrapper,
  Micons,
  UploadFile,
} from "../../../../components/customAntd";
import { AnimeTemplateName } from "../../../../utils/constants";

import Styles from "./styles/buildStorefront.module.scss";

const { Item } = Form;
const { Panel } = Collapse;

const BenefitAndCollectionSort = ({
  themeId,
  t,
  setBenefitsList,
  setCategoriesList,
  swap,
  // collections,
  categoryImages,
  setIsCategoryImageLoading,
  setCategoryImages,
}) => {
  // const [collectionsList, setCollectionsList] = useState(collections);
  const [activeKey, setActiveKey] = useState("");

  const form = Form.useFormInstance();
  const watchBenefitSortBy = Form.useWatch("benefitSortBy", form);

  const handleBenefitsDragEnd = (data) => {
    const { destination, source } = data;
    if (!!destination) {
      if (watchBenefitSortBy === "BenefitType") {
        setBenefitsList((prev) => {
          swap(prev, destination.index, source.index);
          return [...prev];
        });
      } else {
        setCategoriesList((prev) => {
          swap(prev, destination.index, source.index);

          return [...prev];
        });
      }
      setCategoryImages((prev) => {
        swap(prev, destination.index, source.index);

        return [...prev];
      });
    }
  };

  // const handleCollectionDragEnd = (data) => {
  //   const { destination, source } = data;
  //   if (!!destination) {
  //     setCollectionsList((prev) => {
  //       swap(prev, destination.index, source.index);
  //       return [...prev];
  //     });
  //   }
  // };

  // return themeId !== AnimeTemplateName ? (
    return (
    <FormWrapper heading={t("ORDER_BY")}>
      <Item name="benefitSortBy" label={`${t("CATEGORIZE_BENEFITS")}:`}>
        <Radio.Group style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Radio value="BenefitType">{t("BENEFIT_TYPE")}</Radio>
            </Col>
            <Col xs={24} md={12}>
              <Radio value="Category">{t("BENEFIT_CATEGORIES.LABEL")}</Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Item>

      {watchBenefitSortBy && (
        <DragDropContext onDragEnd={handleBenefitsDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Form.List name="benefitSortCriteria">
                    {(fields) =>
                      fields.map(({ key, name }, index) => {
                        return (
                          <Draggable
                            draggableId={`${key}`}
                            index={index}
                            key={`${key}`}
                          >
                            {(provided) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <Collapse
                                    className={Styles["sort-option"]}
                                    activeKey={activeKey ? [activeKey] : []}
                                  >
                                    <Panel
                                      forceRender
                                      key={`${index}`}
                                      collapsible="header"
                                      className={Styles["custom-panel"]}
                                      extra={
                                        <CaretRightOutlined
                                          rotate={
                                            activeKey === `${index}` ? 90 : 0
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setActiveKey((prev) =>
                                              prev === `${index}`
                                                ? ""
                                                : `${index}`
                                            );
                                          }}
                                        />
                                      }
                                      header={
                                        <>
                                          <Micons
                                            icon="drag_handle"
                                            isHover={false}
                                            style={{
                                              fontSize: 24,
                                              color: "var(--color-drag-icon)",
                                            }}
                                            wrapperStyle={{
                                              marginRight: 10,
                                            }}
                                            {...provided.dragHandleProps}
                                          />
                                          <span>
                                            {form.getFieldValue([
                                              "benefitSortCriteria",
                                              name,
                                              "name",
                                            ])}
                                          </span>
                                        </>
                                      }
                                    >
                                      <Item hidden name={[name, "position"]} />
                                      <Row gutter={20}>
                                        {watchBenefitSortBy === "Category" && (
                                          <Col sm={12} xs={24}>
                                            {/* Upload Component */}
                                            <Item
                                              label={t(
                                                "STOREFRONT_UI.UPLOAD_CATEGORY_IMAGE"
                                              )}
                                              name="url"
                                              extra={
                                                <>
                                                  <p>{`${t(
                                                    "FILE_UPLOAD.RECOMMENDED_SIZE"
                                                  )} - ${
                                                    themeId !==
                                                    AnimeTemplateName
                                                      ? "390x82"
                                                      : "562x582"
                                                  }`}</p>
                                                  <p>
                                                    {`${t(
                                                      "FILE_TYPES_SUPPORTED"
                                                    )}: JPG, JPEG, PNG. ${t(
                                                      "MAX_SIZE"
                                                    )}: 5${t("KB")}`}
                                                  </p>
                                                </>
                                              }
                                              valuePropName=""
                                              // rules={[
                                              //   {
                                              //     required:
                                              //       !categoryImages?.[index],
                                              //     message: t(
                                              //       "STOREFRONT_UI.HERO_IMAGE_VALIDATION"
                                              //     ),
                                              //   },
                                              // ]}
                                            >
                                              <UploadFile
                                                showUploadList={false}
                                                placeholder={
                                                  themeId !== AnimeTemplateName
                                                    ? "390x82"
                                                    : "1024x1024 (1:1)"
                                                }
                                                fileFor="collection"
                                                fileSize={0.5} //
                                                accept=".jpg, .jpeg, .png"
                                                style={{ width: "144px" }}
                                                height={144}
                                                image={
                                                  categoryImages?.[index] || ""
                                                }
                                                imageFit="contain"
                                                onLoading={(value) =>
                                                  setIsCategoryImageLoading(
                                                    value
                                                  )
                                                }
                                                onChange={(image) => {
                                                  setCategoryImages((prev) => {
                                                    const arr = [...prev];
                                                    arr[index] = image.s3Url;
                                                    return arr;
                                                  });
                                                }}
                                                // dimensions={{
                                                //   height: 82,
                                                //   width: 390,
                                                // }}
                                              />
                                            </Item>
                                            <Popconfirm
                                              title={t(
                                                "HERO_IMAGE_CONFIRM_DELETE"
                                              )}
                                              onConfirm={() => {
                                                setCategoryImages((prev) => {
                                                  const arr = [...prev];
                                                  arr[index] = "";
                                                  return arr;
                                                });
                                              }}
                                              onCancel={() => {}}
                                              okText={t("YES")}
                                              cancelText={t("NO")}
                                            >
                                              <Button
                                                type="link"
                                                icon={<DeleteOutlined />}
                                                style={{
                                                  color:
                                                    "var(--color-form-error)",
                                                }}
                                              >
                                                {t("REMOVE_CATEGORY_IMAGE")}
                                              </Button>
                                            </Popconfirm>
                                          </Col>
                                        )}
                                        <Col
                                          sm={
                                            watchBenefitSortBy === "Category"
                                              ? 12
                                              : 24
                                          }
                                          xs={24}
                                        >
                                          {/* Right Column */}
                                          <Row gutter={20}>
                                            <Col
                                              sm={
                                                watchBenefitSortBy ===
                                                "Category"
                                                  ? 24
                                                  : 12
                                              }
                                            >
                                              <Item
                                                name={[name, "sortBy"]}
                                                label={`${t("SORT_BY")}:`}
                                              >
                                                <Select
                                                  options={[
                                                    {
                                                      label: t("CREATED_DATE"),

                                                      value: "CreatedDate",
                                                    },
                                                    {
                                                      label: t("START_DATE"),
                                                      value: "StartDate",
                                                    },
                                                    {
                                                      label: t("ACTIVE_DATE"),
                                                      value: "ActiveDate",
                                                    },
                                                  ]}
                                                  onChange={(val) => {
                                                    if (
                                                      watchBenefitSortBy ===
                                                      "BenefitType"
                                                    ) {
                                                      setBenefitsList(
                                                        (prev) => {
                                                          prev[name].sortBy =
                                                            val;
                                                          return [...prev];
                                                        }
                                                      );
                                                    } else {
                                                      setCategoriesList(
                                                        (prev) => {
                                                          prev[name].sortBy =
                                                            val;
                                                          return [...prev];
                                                        }
                                                      );
                                                    }
                                                  }}
                                                ></Select>
                                              </Item>
                                            </Col>
                                            <Col
                                              sm={
                                                watchBenefitSortBy ===
                                                "Category"
                                                  ? 24
                                                  : 12
                                              }
                                            >
                                              <Item
                                                name={[name, "sortOrder"]}
                                                label={`${t("SORT_ORDER")}:`}
                                              >
                                                <Select
                                                  options={[
                                                    {
                                                      label: t("ASCENDING"),
                                                      value: "asc",
                                                    },
                                                    {
                                                      label: t("DESCENDING"),
                                                      value: "desc",
                                                    },
                                                  ]}
                                                  onChange={(val) => {
                                                    if (
                                                      watchBenefitSortBy ===
                                                      "BenefitType"
                                                    ) {
                                                      setBenefitsList(
                                                        (prev) => {
                                                          prev[name].sortOrder =
                                                            val;
                                                          return [...prev];
                                                        }
                                                      );
                                                    } else {
                                                      setCategoriesList(
                                                        (prev) => {
                                                          prev[name].sortOrder =
                                                            val;
                                                          return [...prev];
                                                        }
                                                      );
                                                    }
                                                  }}
                                                />
                                              </Item>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Panel>
                                  </Collapse>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })
                    }
                  </Form.List>
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      )}
    </FormWrapper>
  )
  //  : (
    // <></>
    // <DragDropContext onDragEnd={handleCollectionDragEnd}>
    //   <Droppable droppableId="droppable">
    //     {(provided) => {
    //       return (
    //         <div {...provided.droppableProps} ref={provided.innerRef}>
    //           {collectionsList?.map((item, index) => (
    //             <Draggable
    //               draggableId={`${item.id}`}
    //               index={index}
    //               key={`${item.id}`}
    //             >
    //               {(provided) => {
    //                 return (
    //                   <div ref={provided.innerRef} {...provided.draggableProps}>
    //                     <FormWrapper
    //                       heading={`${t("STOREFRONT_UI.COLLECTION")} ${
    //                         index + 1
    //                       }`}
    //                       headerStyles={{ display: "flex" }}
    //                       icon={
    //                         <Micons
    //                           icon="drag_handle"
    //                           isHover={false}
    //                           style={{
    //                             fontSize: 24,
    //                             color: "var(--color-drag-icon)",
    //                           }}
    //                           wrapperStyle={{
    //                             marginRight: 10,
    //                           }}
    //                           {...provided.dragHandleProps}
    //                         />
    //                       }
    //                     >
    //                       <Item
    //                         label={`${t("STOREFRONT_UI.COLLECTION_NAME")} :`}
    //                         name={item.name}
    //                       >
    //                         <Input
    //                           disabled
    //                           placeholder={t(
    //                             "STOREFRONT_UI.COLLECTION_NAME_PLACEHOLDER"
    //                           )}
    //                         />
    //                       </Item>
    //                     </FormWrapper>
    //                   </div>
    //                 );
    //               }}
    //             </Draggable>
    //           ))}
    //           {provided.placeholder}
    //         </div>
    //       );
    //     }}
    //   </Droppable>
    // </DragDropContext>
  // );
};

export default BenefitAndCollectionSort;

import { Input, Spin } from "antd";

import { Micons, Modal } from "../../../components/customAntd";
import { ReactComponent as OtpIcon } from "../../../assets/images/otp.svg";

import styles from "./styles.module.scss";
import { CheckCircleFilled } from "@ant-design/icons";

const OtpModal = ({
  isOpen,
  onCancel,
  email,
  handleOtpSubmit,
  checkingOtp,
  otpError,
  setOtpError,
  isValidOtp,
}) => {
  const handleKeyDown = async (e) => {
    if (otpError) setOtpError(null);
    if (
      !(e.ctrlKey || e.metaKey) &&
      e.keyCode == 86 && //Not pasting
      !(
        e.key.match("^[0-9]*$") ||
        ["ArrowLeft", "ArrowRight", "Backspace", "Tab"].includes(e.key)
      )
    ) {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    if (!e.clipboardData.getData("text").match("^[0-9]*$")) e.preventDefault();
  };

  return (
    <Modal
      className={styles.modal}
      rootClassName={styles.root}
      open={isOpen}
      footer={null}
      onCancel={onCancel}
    >
      <OtpIcon />
      {otpError?.error_code === "TOO_MANY_ATTEMPTS" ||
      otpError?.error_code === "OTP_EXPIRED" ? (
        <p className={styles.text}>{otpError?.message}</p>
      ) : (
        <>
          <p className={styles.text}>
            Please enter the code sent to <strong>{email}</strong>
          </p>
          {checkingOtp ? (
            <Spin size="small" />
          ) : isValidOtp ? (
            <CheckCircleFilled style={{ color: "#9048F4" }} />
          ) : (
            <Input.OTP
              onChange={handleOtpSubmit}
              size="large"
              className={styles["otp-input-wrapper"]}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
            />
          )}
          {otpError?.error_code === "INVALID_OTP" && (
            <p className={styles.error}>{otpError?.message}</p>
          )}
        </>
      )}
    </Modal>
  );
};

export default OtpModal;

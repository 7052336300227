import { useEffect, useState } from "react";
import { message, Upload } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, Micons } from "../../../customAntd";
import {
  uploadFiles,
  uploadMemberIdCsv,
} from "../../../../utils/common/apiUtils";

import Styles from "./styles/upload.module.scss";

const { Dragger } = Upload;

const UploadFile = ({
  isSingleFile = true,
  files = [],
  fileFor,
  isUploadIpfs = false,
  fileSize = 1,
  onChange,
  image,
  showFileName,
  accept,
  placeholder,
  className,
  onLoading,
  imageFit,
  isLogoImage,
  isIconImage = false,
  dimensions,
  ...props
}) => {
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [fileSizeComp, setfileSizeComp] = useState(1);
  const [fileName, setFileName] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    const size = Number(fileSize);
    if (!isNaN(size)) {
      setfileSizeComp(fileSize);
    }
  }, [fileSize]);

  useEffect(() => {
    if (files?.length > 0) {
      setFileName(files[0]?.name);
    }
  }, []);

  const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function(event) {
        const img = new Image();
        img.onload = function() {
          const width = this.width;
          const height = this.height;
          const isValid = width === dimensions.width && height === dimensions.height;
          resolve(isValid);
        };
        img.src = event.target.result;
      };
      reader.onerror = function(error) {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleBeforeUpload = async (file, fileList) => {
    const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
    const currentFileIndexOf = files.findIndex((i) => i.name === file.name);
    const allowedExtensions = accept
      .split(", ")
      .map((item) => item.substring(1));
    if (!allowedExtensions.includes(extension)) {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.ONLY")} ${allowedExtensions.join(".")} ${t(
          "FILE_UPLOAD.EXTENSIONS_ALLOWED"
        )}`,
      });
      return false;
    } else if (currentFileIndexOf !== -1 && !isSingleFile) {
      message.open({
        type: "error",
        content: t("FILE_UPLOAD.EXISTS"),
      });
      return false;
    } else if (file.size / 1024 / 1024 > fileSizeComp) {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.SIZE_ERROR")} ${fileSizeComp}${t("MB")}.`,
      });
      return false;
    } else if (dimensions && !(await checkImageDimensions(file))) {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.DIMENSION_ERROR")}.`,
      });
      return false;
    } else {
      if (isSingleFile) {
        setIsFileLoading(true);
        if (onLoading) {
          onLoading(true);
        }
        if (accept === ".csv") {
          // For storefront member id csv only. This uploads to the backend server
          const uploadFile = await uploadMemberIdCsv(props?.storeFrontId, {
            file: file,
          });
          if (uploadFile?.status === 200) {
            onChange(uploadFile.data);
            setFileName(file.name);
          } else {
            message.error({
              content: uploadFile.data.message,
              key: "error",
              duration: 2,
            });
          }
          if (onLoading) {
            onLoading(false);
          }
        } else {
          // For s3 bucket uploads
          const uploadFile = await uploadFiles({
            fileFor: fileFor,
            isUploadIpfs: isUploadIpfs,
            files: file,
            isLogoImage: isLogoImage,
            isIconImage: isIconImage,
          });
          if (uploadFile?.status === 200) {
            onChange(uploadFile.data);
            setFileName(file.name);
          }
          if (onLoading) {
            onLoading(false);
          }
        }
        setIsFileLoading(false);
      } else {
        onChange((prev) => [...prev, file]);
      }
      return false;
    }
  };

  return (
    <Dragger
      beforeUpload={handleBeforeUpload}
      accept={accept}
      disabled={isFileLoading}
      {...(!isSingleFile && { multiple: true })}
      className={`${Styles.upload} ${
        fileName && showFileName ? Styles["disable-pointer-events"] : ""
      } ${className ? `${className} ` : ""}`}
      {...props}
    >
      {fileName && showFileName && (
        <Button type="link" className={Styles["change-file"]}>
          <Micons icon="autorenew" isHover={false} style={{ fontSize: 16 }} />
          {t("FILE_UPLOAD.CHANGE")}
        </Button>
      )}
      {isFileLoading ? (
        <>
          <LoadingOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
          <p className="ant-upload-hint">{`${t("UPLOADING")}...`}</p>
        </>
      ) : (
        <>
          {image ? (
            <img
              src={image}
              alt="avatar"
              style={{
                width: "100%",
                height: props.height - 2,
                objectFit: imageFit && imageFit !== "" ? imageFit : "cover",
              }}
            />
          ) : fileName && showFileName ? (
            <div className="fileName">
              <p>
                <Micons icon="description" isHover={false} />
                {fileName}
              </p>
            </div>
          ) : (
            <>
              <UploadOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
              <p className="ant-upload-hint">
                {placeholder ? placeholder : t("UPLOAD")}
              </p>
            </>
          )}
        </>
      )}
    </Dragger>
  );
};

export default UploadFile;
